// about home five

.about__image{
    img{
        max-width: 100%;
        height: 650px;
        border-radius: 16px 16px 16px 160px;
        @include mq($sm){
            height: 100%;
        }
    }
}
.step__icon {
    height: 76px;
    min-width: 76px;
    background-color: var(--rts-gray);
    color: var(--rts-primary);
    font-size: 24px;
    font-weight: 600;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    @include mq($xs){
        height: 60px;
        min-width: 60px;
    }
}
.spacing{
    margin-top: -120px;
    position: relative;
    @include mq($lg){
        margin-top: 0;
    }
}
.about__content {
    padding: 60px 90px 60px 60px;
    box-shadow: 0px 4px 50px rgba(132, 132, 132, 0.16);
    border-radius: 16px 16px 160px 16px;
    min-width: 690px;
    background-color: var(--rts-white);
    @include mq($lg){
        border-radius: 0;
    }
    @include mq($sm){
        min-width: 100%;
        padding: 60px 50px;
        border-radius: 0;
    }
    @include mq($xs){
        padding: 40px 30px;
    }
}
.about__shape.max-content {
	left: 30%;
	position: absolute;
	z-index: -1;
}

// home six about
.about__section__bg{
    background-color: #F5F3EA;
}
.shape__image {
	position: absolute;
	left: -50.5%;
	bottom: -10%;
}
.about__image__section{
    .about__image__one{
        img{
            border-radius: 10px;
        }
    }
    .about__applicant{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 30px;
        z-index: 1;
        @include mq($xs){
            position: relative;
            left: 0;
            transform: translate(0);
            bottom: 0;
            margin-bottom: 40px;
        }
    }
    .about__image__two{
        position: relative;
        bottom: 50px;
        img{
            border-radius: 160px;
            @include mq($xs){
                border-radius: 10px;
            }
        }
    }
}
.rts__listing{
    &.style__six{
        .single__listing .icon{
            background-color: var(--rts-button-3);
        }
    }
}