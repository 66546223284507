.similar__form {
    max-width: 600px;
    min-width: 600px;
    @include mq($xs){
        min-width: 100%;
        max-width: 100%;
    }

    input:not([type="checkbox"]) {
        border: 1px solid var(--rts-border);
        padding: 15px 20px 15px 45px;
        border-radius: 4px;
        width: 100%;
    }

    i.icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
    }

}

.form__padding {
    padding: 40px 45px;
}

.has__line {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--rts-border);
        bottom: 12px;
        left: 0;
        z-index: 1;
    }

    p {
        max-width: max-content;
        margin: 0 auto;
        padding: 0 12px;
        font-weight: 500;
        position: relative;
        z-index: 2;
        background-color: var(--rts-white);
    }
}

.tab__switch {
    display: flex;
    gap: 30px;

    .rts__btn {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 16px 30px;
        width: 100%;
        border-radius: 4px;
        background-color: var(--rts-gray);
        
        &.active {
            background-color: var(--rts-primary);
            color: var(--rts-white);
        }
    }
}

// form submit
.form-group {
    .rts__btn {
        padding: 18px 0;
        border-radius: 4px;
        font-weight: 500;
    }
}

.is__social {
    button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 15px 20px;
        border-radius: 4px;
        background-color: var(--rts-gray);
        width: 100%;
        font-size: 16px;
        color: var(--rts-para);
        transition: var(--transition);

        &:hover {
            background-color: var(--rts-primary);
            color: var(--rts-white);
        }
    }
}

// modal opening
.similar__modal {
    .modal-dialog {
        min-width: 600px;
        border-radius: 16px;
        border: 1px solid var(--rts-border);
        @include mq($xs){
            min-width: auto;
        }
    }
}

// contact method no border
.is__half__section {
    margin-top: -150px;
    @include mq($sm){
        margin-top: 0;
        padding-top: 100px;
    }
}

.is__no__border {
    .rts__workprocess__box {
        border: none;
    }
}

.shadow-rt-sm {
    box-shadow: 0px 4px 80px rgba(28, 28, 28, 0.1);


}