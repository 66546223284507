.rts__btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    cursor: pointer;
    text-align: center;
    &:active{
        color: inherit;
    }
    &:focus{
        outline: none;
    }
    &:disabled{
        cursor: not-allowed;
    }
}


.fill__btn{
    background-color: var(--rts-button-1);
    border: 1px solid var(--rts-button-1);
    transition: var(--transition);
    color: var(--rts-white);
    &:hover{
        background-color: var(--rts-button-3);
        border-color:var(--rts-button-3);
    }
    &.he-2{
        background-color: var(--rts-button-2);
        border-color: var(--rts-button-2);
        &:hover{
            background-color: var(--rts-button-3);
            border-color:var(--rts-button-3);
        }
    }
    &.he-3{
        background-color: var(--rts-button-3);
        border-color: var(--rts-button-3);
        &:hover{
            background-color: var(--rts-button-1);
            border-color:var(--rts-button-1);
        }
    }
    &.be-1{
        background-color: var(--rts-button-1);
        border-color: var(--rts-button-1);
        &:hover{
            background-color: var(--rts-button-3);
            border-color:var(--rts-button-3);
        }
    }
}
.no__fill__btn{
    background-color: transparent;
    border: 1px solid var(--rts-button-1);
    transition: var(--transition);
    color: var(--rts-button-1);
    &:hover{
        background-color: var(--rts-button-3);
        border-color:var(--rts-button-3);
        color: var(--rts-white);    
    }
    &.he-2{
        border-color: var(--rts-button-2);
        color: var(--rts-button-3);
        &:hover{
            background-color: var(--rts-button-2);
            border-color:var(--rts-button-2);
            color: var(--rts-white);
        }
    }
    &.he-3{
        border-color: var(--rts-button-3);
        color: var(--rts-button-3);
        &:hover{
            background-color: var(--rts-button-3);
            border-color:var(--rts-button-3);
            color: var(--rts-white);
        }
    }
    &.active{
        background-color: var(--rts-button-1);
        color: var(--rts-white) !important;
        border-color: var(--rts-button-1);
    }
}
.small__btn{
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    gap: 10px;
    i{
        font-size: 15px;
    }
}
.pricing__btn{
    padding: 17px 35px;
    color: var(--rts-heading);
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--rts-button-1);
    background-color: var(--rts-white);
    border-radius: 10px;
    transition: var(--transition);
    &:hover{
        background-color: var(--rts-button-1);
        color: var(--rts-white);
        border-color: var(--rts-button-1);
    }
}
.common__btn{
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 15px 16px;
}
// border radius
.border-4{
    border-radius: 4px;
}
.border-6{
    border-radius: 6px;
}
.border-10{
    border-radius: 10px;
}