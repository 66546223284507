// home one workprocess
.rts__workprocess__box {
    background: linear-gradient(226.58deg, #FEF2EB 1.52%, #F1F1F1 47.05%, #E4E0EA 99.84%);
    padding: 30px 42px;
    text-align: center;
    .rts__icon {
        height: 76px;
        width: 76px;
        background-color: var(--rts-white);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: grid;
        place-items: center;
        margin: 0 auto 20px;

        img {
            height: 40px;
            width: 40px;
        }
    }
    .process__title {
        margin-bottom: 15px;
    }
    .work__readmore {
        a {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            letter-spacing: -0.5px;
            color: var(--rts-heading);
            transition: var(--transition);
            &:hover{
                color: var(--rts-button-1);
            }
            i {
                color: var(--rts-button-1);
            }
        }
    }
    &.is__contact{
        background: transparent;
        border: 1px solid rgba(#7D8087, $alpha: 0.3);
        padding: 40px 36px;
        transition: var(--transition);
        .rts__icon{
            background-color: #F1F1F1;
            box-shadow: none;
            transition: var(--transition);
        }
        &:hover{
            background: linear-gradient(226.58deg, #FEF2EB 1.52%, #F1F1F1 47.05%, #E4E0EA 99.84%);
            border-color: transparent;
            .rts__icon{
                background-color: var(--rts-white);
            }
        }
    }

}

// home two workprocess
.single__feature__list {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .single__item {
        .icon {
            height: 76px;
            width: 76px;
            background-color: var(--rts-gray);
            display: grid;
            place-items: center;
            border-radius: 6px;
        }

        .content {
            .h6 {
                font-weight: 600;
            }
        }
    }
}
.rts__workprocess__image{
    position: relative;
    img{
        max-height: 700px;
        border-top-left-radius: 320px;
        border-top-right-radius: 320px;
    }
    .rts__applicants{
        position: absolute;
        bottom: 50px;
        left: -150px;
        @include mq($lg){
            left: -100px;
        }
        @include mq($md){
            left: 0;
        }
    }
}
.rts__applicants {
    max-width: max-content;
    border-radius: 10px;
    background-color: var(--rts-white);
    padding: 20px 30px;
    box-shadow: 0px 4px 40px rgba(61, 61, 61, 0.16);

    .font-20 {
        color: var(--rts-heading);
    }
}

.applicant__list {
    display: flex;
    margin-right: 15px;
    flex-wrap: wrap;
    .single__list {
        margin-right: -15px;
        position: relative;
        .icon-plus{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            background-color: rgba(#553cdf, $alpha: 0.6) ;
            border-radius: 50%;
            display: grid;
            place-items: center;
            color: var(--rts-white);
            font-size: 20px;
            border: 5px solid #F9F8FF;
        }
    }

    .single__list img {
        height: 56px;
        width: 56px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        border: 5px solid #F9F8FF;
    }
}