select{
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
}
// nice select
.nice-select {
  width: 100%;
  height: auto;
  font-size: inherit;
  position: relative;

  &.open::before {
      content: "\f077";
      font-family: var(--fontawesome-pro);
      transition: var(--transition);
  }

  ul.list {
      width: 100%;

      li.selected {
          font-weight: 500;
      }
  }

  &:focus {
      border-color: transparent;
  }

  &::before {
      position: absolute;
      content: "\f078";
      font-family: var(--fontawesome-pro);
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      color: var(--rts-heading);
      font-size: 12px;
      transition: var(--transition);
  }

  &::after {
      border-bottom: 0;
      border-right: 0;
  }
}

// switcher style
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--rts-button-1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked+.slider {
  background-color: var(--rts-button-1);
}

input:checked+.slider:before {
  transform: translateX(30px);
}

.newsletter {
  position: relative;
  max-width: 310px;
  @include mq($xs){
    max-width: 100%;
  }

  input {
    position: relative;
    padding: 20px 18px;
    padding-right: 120px;
    border-radius: 10px;
    border: 0;
    width: 100%;
  }

  button {
    position: absolute;
    right: 15px;
    padding: 10px;
    border-radius: 6px;
    font-size: 16px;
  }
}

.single__input {
  position: relative;
}

// checkbox style
.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  &::before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid var(--rts-button-1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 2px;
    margin-right: 10px;
    top: -2px;
  }
}
.checkbox input:checked + label::after {
	content: "";
	display: block;
	position: absolute;
	top: 8px;
	left: 4px;
	width: 5px;
	height: 10px;
	border: solid var(--rts-white);
	border-width: 0 1px 1px 0;
	transform: rotate(45deg);
}
.checkbox input:checked + label::before {
  background-color: var(--rts-button-1);
  border-radius: 2px;
}
textarea, input {
  &::placeholder{
    color: var(--rts-para) !important;
  }
}

// form
.job__contact{
  &.is__contact{
    background: transparent;
    padding: 0;
    input, textarea{
      border: 1px solid var(--rts-border) !important;
    }
  }
}