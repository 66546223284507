.px-20 {
    padding: 0 20px;
}

.top-30 {
    top: 30px !important;
}
// dashboard profile action
.rts__dropdown{
    padding: 15px 15px;
    min-width: 230px;
    max-width: 360px;
    li{

        a{
            font-weight: 500;
            padding: 6px 15px;
            transition: var(--transition);
            &:hover{
                background-color: var(--rts-button-1);
                color: var(--rts-white);
            }
        }
    }
}
// dashboard profile action
.notification__dropdown{
    padding: 0;
}
.rts__dropdown__content{
    .notification__header{
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--rts-border);
        padding-bottom: 15px;
        h6{
            font-size: 18px;
            color: var(--rts-para);
        }
        a i{
            color: var(--rts-para);
            font-size: 18px;
        }
    }
    .notification__date{
        padding: 5px 15px;
        background-color: var(--rts-gray);
        display: inline-block;
        width: 100%;
        font-weight: 500;
    }
    .single__notification{
        padding: 15px 15px;
        border-bottom: 1px solid var(--rts-border);
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
        transition: var(--transition);
        &:last-child{
            border-bottom: 0;
        }
        &:hover{
            background-color: var(--rts-gray);
        }
        .notification__image{
            img{
                height: 40px;
                min-width: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .notification__content{
            p{
                font-size: 16px;
                line-height: 1.2;
                font-weight: 500;
                color: var(--rts-para);
            }
        }
        .time{
            font-size: 14px;
            
            font-weight: 500;
            min-width: max-content;
        }
    }
}
// dashboard mobile menu
.sidebar__action {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--rts-button-3);
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    max-width: max-content;
}
.message__action{
    @extend .sidebar__action;
}
.user__info{
    .user__image.if__employer{
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background-color: var(--rts-gray);
        display: grid;
        place-items: center;
        img{
            height: 32px;
            width: 32px;
            object-fit: cover;
        }
    }
}
// dashboard menu
.mobile__padding {
    @include mq($md) {
        padding: 25px 20px;
    }
}

.template-dashboard {
    background: linear-gradient(228.24deg, #FEF2EB 0%, #F1F1F1 46.23%, #E4E0EA 99.82%);
}

.rts__dashboard__header {
    border-bottom: 1px solid rgba(#7D8087, $alpha: 0.2);
    z-index: 9;
}

.dropdown__icon {
    cursor: pointer;

    i {
        color: var(--rts-button-3);
    }
}

.notification__icon {
    position: relative;
    cursor: pointer;

    i {
        font-size: 22px;
        color: var(--rts-button-3);
    }

    .notification__count {
        height: 16px;
        min-width: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        border-radius: 50%;
        color: var(--rts-white);
        position: absolute;
        top: 0;
        background-color: var(--rts-button-1);
        left: 8px;

    }
}

// dashboard menu
.dashboard__left::-webkit-scrollbar {
    width: 6px;
}

.dashboard__left::-webkit-scrollbar-thumb {
    background: var(--rts-button-1);
    border-radius: 5px;
}

.dashboard__left {
    min-width: 360px;
    background: var(--rts-white);
    padding: 30px;
    max-width: 100%;
    position: fixed;
    height: 100vh;
    overflow-y: scroll;
    transition: var(--transition);
    margin-top: 100px;

    @include mq($md) {
        margin-left: -360px;
    }

    &.active {
        margin-left: 0;
    }

    .dash__menu {
        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 10px;

                a {
                    padding: 12px 20px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    color: #4B5563;
                    font-weight: 500;
                    transition: var(--transition);
                    position: relative;
                    z-index: 1;

                    &::before {
                        content: '';
                        background: linear-gradient(228.24deg, #FEF2EB 0%, #F1F1F1 46.23%, #E4E0EA 99.82%);
                        border-radius: 6px;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: -1;
                        opacity: 0;
                        transition: var(--transition);
                    }

                    &:hover {
                        color: var(--rts-button-3);

                        &::before {
                            opacity: 1;
                        }

                        svg path[fill] {
                            fill: var(--rts-button-1);
                        }

                        svg path[stroke] {
                            stroke: var(--rts-button-1);
                        }
                    }

                    &.active {
                        background: linear-gradient(228.24deg, #FEF2EB 0%, #F1F1F1 46.23%, #E4E0EA 99.82%);
                        color: var(--rts-button-3);

                        svg path[fill] {
                            fill: var(--rts-button-1);
                        }

                        svg path[stroke] {
                            stroke: var(--rts-button-1);
                        }
                    }
                }
            }
        }
    }

    .dash__logout {
        margin-top: 500px;
        position: relative;
        margin-bottom: 100px;

        @include mq($md) {
            margin-top: 300px;
        }

        .logout__image {
            border-radius: 16px;
            background-color: var(--rts-gray);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 20px;
        }

        a.logout__btn {
            background: var(--rts-button-1);
            color: var(--rts-white);
            padding: 20px 0;
            text-align: center;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            line-height: 1;
            transition: var(--transition);

            &:hover {
                color: var(--rts-white);
                background: var(--rts-button-3);
            }
        }
    }

}

.p-30 {
    padding: 30px;
}

.dashboard__right {
    padding: 30px;
    width: calc(100% - 360px);
    margin-left: 360px;
    margin-top: 100px;

    @include mq($md) {
        margin-left: 0;
        width: 100%;
    }

    @include mq($xs) {
        padding: 20px;
    }
}

// dashboard content css
.profile__view {
    min-width: calc(100% - 40%);

    @include mq($xl) {
        min-width: 100%;
    }
}

.overview__content {
    gap: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include mq($xl) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($xs) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.single__overview {
    background-color: var(--rts-white);
    padding: 30px 40px;
    border-radius: 10px;
    display: flex;
    gap: 30px;
    flex: 1;
    align-items: center;


    @media screen and (min-width: 1399px) and (max-width: 1680px) {
        padding: 20px;
        gap: 15px;
    }

    @include mq($lg) {
        padding: 20px;
    }
    .content h5{
        @include mq($xl){
            font-size: 25px;
        }
    }
    .icon {
        height: 70px;
        min-width: 70px;
        border-radius: 10px;
        background-color: var(--rts-gray);
        display: grid;
        place-content: center;
    }
}

.radius-16 {
    border-radius: 16px;
}

.chart__and__notification {
    display: flex;

    @include mq($xl) {
        flex-wrap: wrap;
    }
}

// profile view
.my__profile__tab {
    padding: 30px;

    .nav-tabs {
        border-bottom: none;

        .nav-link {
            color: var(--rts-para);
            padding: 15px;
            border: none;
            border-radius: 6px;
            line-height: 100%;

            &.active {
                background-color: var(--rts-gray);
                color: var(--rts-button-3);
            }
        }
    }
}

.profile__view {
    &__tab {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        .nav-link {
            padding: 10px;
            transition: var(--transition);
            border-radius: 6px;
            @include mq($xs){
                font-size: 14px;
            }
            &.active {
                background-color: var(--rts-button-1);
                color: var(--rts-white);

            }
        }
    }
}

// notification area
.profile__view,
.notification__area {
    padding: 30px;
    border-radius: 16px;

    @include mq($xl) {
        width: 100%;
    }
}

.notification__list {
    .notification__item {
        display: flex;
        gap: 30px;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--rts-border);
        @include mq($xs){
            flex-wrap: wrap;
        }

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .icon {
            height: 70px;
            min-width: 70px;
            border-radius: 6px;
            background-color: var(--rts-gray);
            display: grid;
            place-content: center;

            img {
                height: 40px;
                width: 40px;
            }
            &.is__image{
                img{
                    height: 70px;
                    width: 70px;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }
        }

        .content {
            a {
                color: var(--rts-button-3);
            }

            .time {
                margin-top: 10px;
            }
        }
    }
}

// profile tab content
.info__top {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    margin-top: 30px;

    @include mq($xs) {
        flex-wrap: wrap;
    }

    .author__image {
        background: var(--rts-gray);
        border-radius: 16px;

        img {
            height: 160px;
            width: 160px;
            border-radius: 6px;
        }
    }

    .select__image {}

    .delete__data {
        height: 52px;
        min-width: 52px;
        border-radius: 6px;
        background-color: var(--rts-gray);
        display: grid;
        place-content: center;
        cursor: pointer;
        color: var(--rts-button-3);

    }
}

// custom upload button
.file-upload {
    position: relative;
    display: inline-block;
}

.file-upload__label {
    display: block;
    padding: 10px 15px;
    color: #fff;
    background: var(--rts-button-1);
    border-radius: .4em;
    transition: background .3s;

    &:hover {
        cursor: pointer;
        background: #000;
    }
}

.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.info__field {
    display: flex;
    flex-direction: column;
    gap: calc(40px / 2);

    .rt-input-group {
        label {
            display: block;
            margin-bottom: 15px;
            color: var(--rts-button-3);
            margin-top: 15px;
            text-transform: capitalize;
            &:first-child{
                margin-top: 0;
            }
            @include mq($xs) {
                margin-top: 15px;
            }
        }

        input,
        .form-select,
        textarea {
            padding: 15px 20px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid var(--rts-border);
            @include mq($sm){
                padding: 12px 20px;
                font-size: 16px;
            }
            &:focus {
                box-shadow: none;
            }
        }
        textarea{
            height: 150px;
        }

    }
}

.user__location {
    iframe {
        height: 390px;
        width: 100%;
        border-radius: 10px;
    }
}

.added__social__link {
    border: 1px solid var(--rts-border);
    padding: 15px 20px;
    max-width: max-content;
    color: var(--rts-button-3);
    border-radius: 6px;
    display: inline-block;
    transition: var(--transition);
    @include mq($sm){
        line-height: 1.2;
    }
    &:hover {
        background-color: var(--rts-button-1);
        color: var(--rts-white);
    }
}

// resume area css
// custom file upload two
.select__image {
    position: relative;
}

.file-upload {
    position: relative;
    display: inline-block;
}

.file-upload__label__two {
    display: block;
    padding: 10px 15px;
    color: var(--rts-button-3);
    background: var(--rts-gray);
    border-radius: .4em;
    transition: background .3s;
    height: 500px;
    width: 755px;

    @include mq($lg) {
        width: 600px;
    }

    @include mq($sm) {
        width: 500px;
        height: 400px;
    }

    @include mq($xs) {
        width: 300px;
        height: 300px;
    }

    text-align: center;
    position: relative;
    border: 2px dotted rgba(#0B0D28, $alpha: 0.5);

    &:hover {
        cursor: pointer;
        background: #000;
        color: var(--rts-white);
    }

    i {
        font-size: 82px;
        display: block;
        margin-bottom: 30px;
        color: var(--rts-button-1);
    }

    span {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    }
}

.file-upload__input__two {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.cv__included {
    flex-wrap: wrap;

    .single__item {
        max-width: 360px;
        min-width: 360px;
        padding: 20px 30px;
        border-radius: 10px;

        @include mq($xs) {
            min-width: 100%;
        }

        i {
            color: var(--rts-button-1);
            cursor: pointer;
        }

        border: 1px solid var(--rts-border);
    }

    .file__type {
        font-weight: 600;
        color: var(--rts-button-3);
    }
}
.template-dashboard{
    .accordion {
        display: flex;
        flex-direction: column;
        gap: 20px;
    
        .accordion-item {
            border: 0;
    
            button {
                background-color: var(--rts-gray);
                border-radius: 0;
                font-weight: 600;
                font-size: 20px;
                border-radius: 6px;
                padding: 20px 30px;
    
                &:focus {
                    box-shadow: none;
                }
                &:not(.collapsed) {
                    box-shadow: none;
                }
            }
        }
    }
}

.my__skillset {
    margin-bottom: 30px;
    padding: 15px 20px;
    background-color: var(--rts-gray);
    border-radius: 6px;

    .skill__tags {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        li {
            background-color: var(--rts-white);
            padding: 10px 15px;
            border-radius: 4px;
            max-width: max-content;
            gap: 15px;
            cursor: pointer;
            display: flex;

            i {
                color: var(--rts-button-1);
                cursor: pointer;
            }

            .skill__item {
                font-size: 20px;
                font-weight: 600;

                &__add i {
                    color: var(--rts-white);
                }
            }

            &:last-child {
                background-color: var(--rts-button-1);
                color: var(--rts-white);
            }
        }
    }
}

// my portfolio area css
.single__portfolio {
    position: relative;

    .delete__icon {
        position: absolute;
        top: 20px;
        right: 35px;
    }

    .delete__icon button {
        height: 40px;
        min-width: 40px;
        display: grid;
        place-items: center;
        background-color: var(--rts-white);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

        i {
            color: var(--rts-button-1);
        }

        transition: var(--transition);

        &:hover {
            background-color: var(--rts-button-1);

            i {
                color: var(--rts-white);
            }
        }
    }

    .portfolio__thumb {
        img {
            border-radius: 6px;
        }
    }
}

// applied job info
.applied__job__info {
    background-color: var(--rts-white);
    padding: 30px;
}

.job__filter {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.search__job {
    min-width: 330px;

    @include mq($xs) {
        min-width: 100%;
    }

    input {
        background-color: var(--rts-gray);
        border: 0;
        padding: 13px 40px;
        border-radius: 4px;
        color: var(--rts-button-3);
        width: 100%;
    }

    i {
        position: absolute;
        left: 25px;
        top: 50%;
        color: var(--rts-button-3);
        transform: translate(-50%, -50%);
    }
}

.filter__job {
    .filter__select {
        background-color: var(--rts-gray);
        min-width: 190px;
        border: none;
        padding: 5px 20px;
    }
}

// single applied job
.applied__job__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.single__applied__job {
    display: flex;
    padding: 30px;
    border: 1px solid var(--rts-border);
    border-radius: 10px;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mq($xxl) {
        flex-wrap: nowrap;
    }
    @include mq($xl) {
        gap: 20px
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 30px;

        @include mq($sm) {
            flex-wrap: wrap;
        }
    }

    .icon {
        height: 122px;
        min-width: 122px;
        background-color: var(--rts-gray);
        border-radius: 50%;
        display: grid;
        place-content: center;

        @include mq($xl) {
            display: none;
        }

        img {
            height: 75px;
            width: 75px;
        }
    }

    .content__flex {
        display: flex;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;
    }

    .content {
        a h6 {
            margin-bottom: 10px;
        }

        &__info {
            display: flex;
            gap: 20px;
            align-items: center;
            flex-wrap: wrap;

            span {
                display: flex;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;

                i {
                    color: var(--rts-button-3);
                }
            }
        }
    }

    .tags {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        a {
            color: var(--rts-para);
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 4px;
            border: 1px solid var(--rts-border);
        }
    }

    .action {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-wrap: wrap;

        &__btn {
            height: 42px;
            width: 46px;
            border-radius: 6px;
            border: 1px solid var(--rts-border);
            display: grid;
            place-items: center;
            transition: var(--transition);

            &:hover {
                background-color: var(--rts-button-1);
                border-color: var(--rts-button-1);

                svg path[fill] {
                    fill: var(--rts-white);
                }

                svg path[stroke] {
                    stroke: var(--rts-white);
                }
            }
        }

        &__operation {
            padding: 12px 18px;
            line-height: 1;
            border-radius: 6px;
            background-color: transparent;

            &.pending {
                background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
                color: var(--rts-button-1);
                transition: var(--transition);

                &:hover {
                    background: var(--rts-button-1);
                    color: var(--rts-white);
                }
            }

            &.rejected {
                background: var(--rts-button-1);
                color: var(--rts-white);
            }

            &.opening {}
        }
    }
}

// candidate message
.user__list__as__messanger {
    background-color: var(--rts-white);
    padding: 30px;
    transition: var(--transition);


    .chat__user__list .single__chat__person::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -4%;
        width: 108%;
        height: 1px;
        background-color: var(--rts-border);
    }
    .chat__user__list .single__chat__person{
        &:last-child{
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.candidate__message {
    padding: 30px;
    border-radius: 16px;
    background-color: var(--rts-white);

    @include mq($sm) {
        padding: 15px;
    }

    &__content {
        border: 1px solid var(--rts-border);
    }

    .message__left {
        max-width: 440px;
        min-width: 440px;
        padding: 30px;
        border-right: 1px solid var(--rts-border);
        overflow: hidden;
        transition: var(--transition);

        @include mq($xl) {
            max-width: 380px;
            min-width: 380px;
            padding: 14px;
            display: none;
        }
    }

    .message__filter {
        margin-top: 20px;

        ul {
            display: flex;
            gap: 20px;
            align-items: center;

            li a {
                color: var(--rts-para);

                &.active {
                    color: var(--rts-button-3);
                }
            }
        }
    }
}

.chat__user__list {
    margin-top: 30px;

    .single__chat__person {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        padding-bottom: 30px;
        margin-bottom: 30px;
        position: relative;
        transition: var(--transition);
        @include mq($xs){
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -10%;
            width: 120%;
            height: 1px;
            background-color: var(--rts-border);
        }

        &:last-child::after {
            display: none;
        }

        .avater {
            img {
                height: 60px;
                min-width: 60px;
                border-radius: 50%;
            }
        }

        .chat__person__meta {
            cursor: pointer;
            p {
                font-size: 16px;
            }
        }
    }

    .right__count {
        gap: 15px;
        display: flex;
        flex-direction: column;
        align-items: end;
        @include mq($xs){
            display: none;
        }
        span {
            font-size: 16px;
            font-weight: 500;
            color: var(--rts-para);

            &.count {
                height: 16px;
                min-width: 16px;
                border-radius: 50%;
                background-color: var(--rts-button-1);
                color: var(--rts-white);
                display: flex;
                justify-content: center;
                font-size: 8px;
                align-items: center;
            }
        }
    }
}

.candidate__message__content {
    display: flex;
}

.message__content {
    width: 100%;
    border-radius: 0;
    background-color: rgba(#7D8087, $alpha: 0.3);

    .chat__header {
        display: flex;
        gap: 15px;
        align-items: center;
        background-color: var(--rts-white);
        padding: 20px 30px;

        .avatar {
            height: 64px;
            width: 64px;
            place-items: center;
            display: grid;
            border-radius: 50%;
            background-color: var(--rts-gray);

            img {
                height: 45px;
                width: 45px;
                object-fit: cover
            }
        }
    }

    .chat__content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 45px;

        @include mq($xs) {
            padding: 15px;
        }

        .msg {
            display: flex;
            gap: 20px;
            width: 50%;
            align-items: end;

            @include mq($xxl) {
                width: 80%;
            }

            @include mq($xl) {
                width: 80%;
            }

            @include mq($xs) {
                flex-wrap: wrap;
                width: 100%;
            }

            .avatar {
                background-color: var(--rts-white);
                height: 46px;
                min-width: 46px;
                border-radius: 50%;
                place-items: center;
                display: grid;

                img {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                }
            }

            .content {
                p {
                    background-color: var(--rts-white);
                    padding: 20px;
                    border-radius: 20px;
                    border-bottom-left-radius: 0;
                }

                span.time {
                    font-size: 12px;
                    font-weight: 500;
                    margin-top: 10px;
                    display: block;
                }
            }

            &.receiver {
                flex-direction: row-reverse;
                justify-content: flex-start;
                width: 100%;

                .content {
                    width: 40%;

                    @include mq($xxl) {
                        width: 80%;
                    }

                    @include mq($xl) {
                        width: 80%;
                    }
                    @include mq($sm){
                        width: 100%;
                    }

                    p {
                        background-color: var(--rts-button-1);
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 20px;
                        color: var(--rts-white);
                    }

                    .time {
                        text-align: right;
                    }
                }
            }
        }

        .input__msg {
            position: relative;

            input[type="text"] {
                width: 100%;
                border: 1px solid rgba(125, 128, 135, 0.5);
                padding: 18px 20px;
                position: relative;
            }

            label {
                position: absolute;
                top: 15px;
                right: 110px;
                cursor: pointer;
            }

            .message__btn {
                padding: 10px 20px;
                border-radius: 6px;
                background-color: var(--rts-button-1);
                color: var(--rts-white);
                position: absolute;
                right: 10px;
                top: 12px;
            }
        }
    }
}

// candidate meeting
.candidate__meeting__content {
    background-color: var(--rts-white);
    border-radius: 16px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.single__meeting__item {
    display: flex;
    padding: 30px;
    border: 1px solid var(--rts-border);
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;

    @include mq($lg) {
        flex-wrap: wrap;
    }

    .calender {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background-color: var(--rts-gray);
        min-width: 122px;
        height: 122px;
        text-align: center;
        line-height: 1;
        gap: 8px;

        .month {
            background-color: var(--rts-button-1);
            color: var(--rts-white);
            padding: 13px 0;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
        }

        .day {
            font-size: 28px;
            font-weight: 600;
            color: var(--rts-button-3);
        }

        .year {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .time {
        display: flex;
        gap: 50px;
        align-items: center;
        margin-left: 60px;
        flex-wrap: wrap;
        @include mq($xxl){
            margin-left: 25px;
            gap: 25px;
        }
        @include mq($xl) {
            margin-left: 0;
            gap: 15px;
        }

        span {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }

    .content {
        h6 {
            font-weight: 600;
            @include mq($xl){
                font-size: 20px;
            }
        }
    }

    .meeting__action {
        display: flex;
        gap: 20px;
        align-items: center;
        @include mq($xxl){
            gap: 15px;
        }
        .action__item {
            position: relative;
            height: 42px;
            width: 42px;
            display: grid;
            place-items: center;
            border: 1px solid var(--rts-border);
            border-radius: 6px;
            transition: var(--transition);

            .notification__item {
                height: 14px;
                width: 14px;
                position: absolute;
                top: 5px;
                left: 5px;
                background-color: var(--rts-button-1);
                border-radius: 50%;
                display: grid;
                place-items: center;
                color: var(--rts-white);
                font-size: 6px;
            }

            &:hover {
                border-color: var(--rts-button-1);
                background-color: var(--rts-button-1);

                svg path[fill] {
                    fill: var(--rts-white);
                }

                svg path[stroke] {
                    stroke: var(--rts-white);
                }

                .notification__item {
                    background-color: var(--rts-white);
                    color: var(--rts-button-3);
                }
            }
        }
    }
}

.single__meeting__left {
    display: flex;
    gap: 30px;
    align-items: center;
    @include mq($xxl){
        gap: 15px;
        font-size: 16px;
    }
    @include mq($xl) {;
        gap: 15px;      
    }
    .content h6{
        @include mq($xxl) {
            font-size: 22px;
        }
    }
}

// password change page
.change__password {
    min-height: 100vh;
    width: 100%;
    background-color: var(--rts-white);
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: flex;

    @include mq($lg) {
        min-height: 100vh;
    }

    @include mq($sm) {
        min-height: 100%;

    }
}

.password__change__form {
    min-width: 630px;
    margin: 0 auto;
    padding: 60px;
    border: 1px solid var(--rts-border);
    border-radius: 20px;

    @include mq($lg) {
        min-width: calc(100% - 60px);
    }

    @include mq($sm) {
        min-width: calc(100% - 120px);
        padding: 30px;
        border: 0;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .rts-input-group {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;

        label {
            font-weight: 500;
            font-size: 16px;
            color: var(--rts-button-3);
        }

        input {
            border: 1px solid rgba(#737477, $alpha: 0.3);
            border-radius: 4px;
            padding: 16px 20px;
            padding-left: 50px;
            font-size: 16px;
            font-weight: 500;
            width: 100%;
            color: var(--rts-button-3);
        }

        .input-box {
            position: relative;
        }

        .password__icon {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .rts__btn {
        max-width: max-content;
        margin-top: 20px;
        border-radius: 4px;
        padding: 18px 25px;
    }

    .gray__btn {
        background-color: #F4F6F5;
        color: var(--rts-button-3);
    }
}

// job alerts page
.candidate__job__alerts {
    padding-top: 40px;
    overflow-y: scroll;
    cursor: pointer;

    .thead-dark {
        border-bottom: 1px solid var(--rts-border);

        th {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
}

.last__col {
    text-align: right;
}

.rts__table__body {
    .single__data__table {
        border-bottom: 1px solid var(--rts-border);

        td,
        th {
            vertical-align: middle;
            color: var(--rts-para);
            padding-top: 20px;
            padding-bottom: 30px;
            min-width: 265px;
        }

        .data__title {
            font-size: 24px;
            font-weight: 600;
            color: var(--rts-button-3);
        }
    }

    .alert__info {
        h6 {
            margin-bottom: 15px;
            color: var(--rts-button-1);
        }

        p {
            color: var(--rts-para);
        }

        .tags {
            margin-top: 20px;
            display: flex;
            gap: 15px;
            flex-wrap: wrap;

            a {
                border: 1px solid var(--rts-border);
                padding: 10px;
                border-radius: 6px;
                color: var(--rts-para);
                line-height: 1;
                transition: var(--transition);

                &:hover {
                    background-color: var(--rts-button-1);
                    color: var(--rts-white);
                    border-color: var(--rts-button-1);
                }
            }
        }
    }
}

.data__action {
    display: flex;
    justify-content: end;
    align-items: center;

    .action {
        display: flex;
        gap: 15px;
        align-items: center;

        .action__btn {
            height: 42px;
            width: 46px;
            border-radius: 6px;
            border: 1px solid var(--rts-border);
            display: grid;
            place-items: center;
            transition: var(--transition);

            &:hover {
                background-color: var(--rts-button-1);
                border-color: var(--rts-button-1);

                svg path[fill] {
                    fill: var(--rts-white);
                }

                svg path[stroke] {
                    stroke: var(--rts-white);
                }
            }
        }
    }
}

.rt-input-group {
    span {
        display: block;
        margin-bottom: 15px;
        margin-top: -10px;
    }

    .position-relative {
        position: relative;

        .rts__btn {
            background-color: var(--rts-button-1);
            padding: 12px 14px;
            border-radius: 6px;
            color: var(--rts-white);
            position: absolute;
            right: 10px;
            transform: translate(0, -55%);
            @include mq($sm){
                top: -4px;
                padding: 8px 12px;
                right: 5px;
            }
        }
    }
}

.info__field .rts__btn {
    margin-top: 30px;
    max-width: max-content;
    padding: 18px 26px;
    margin-left: 15px;
    border-radius: 6px;

    @include mq($sm) {
        padding: 15px 20px;
    }
}

// employer dashboard css
.middle__content {
    display: flex;
    align-items: center;
    gap: 50px;
    @include mq($xs){
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}

.job__post {
    display: flex;
    align-items: center;
    gap: 30px;
   
    .icon__text {
        @include mq($xs){
            display: none;
        }
        i {
            color: var(--rts-button-3);
        }
    }

    .content {
        span {
            display: block;
        }
    }
}

.applied__status {
    span {
        border: 1px solid var(--rts-border);
        border-radius: 6px;
        padding: 5px 10px;
    }
}

// employer job pricing css
.package__selection {
    background-color: var(--rts-white);
    padding: 30px;
    border-radius: 16px;
    display: flex;
    gap: 30px;
    @include mq($sm){
        display: grid;
    }
}

.single__package__selection {
    padding: 30px;
    border-radius: 10px;
    background-color: var(--rts-gray);
    display: flex;
    flex: 1;

    .form-selection {
        display: none;
    }

    .form-selection-label {
        position: relative;
        cursor: pointer;
        margin-left: 50px;

        &::before {
            content: '';
            -webkit-appearance: none;
            background-color: var(--rts-white);
            display: inline-block;
            position: absolute;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            height: 28px;
            width: 28px;
            border-radius: 50%;
            left: -50px;
            top: 50%;
            transform: translate(0, -50%);
            border: 2px solid var(--rts-button-1);
        }
    }

    .form-selection[type="radio"]:checked+.form-selection-label::before {
        background-color: var(--rts-button-1);
        border: 4px solid var(--rts-white);
        box-shadow: none;
        height: 28px;
        width: 28px;
    }
}

.dash__pricing__plan {
    background-color: var(--rts-white);
    padding: 30px;
    border-radius: 16px;
}

.action__operation {
    padding: 12px 18px;
    line-height: 1;
    border-radius: 6px;
    background-color: transparent;

    &.pending {
        background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
        color: var(--rts-button-1);
        transition: var(--transition);

        &:hover {
            background: var(--rts-button-1);
            color: var(--rts-white);
        }
    }
}

// candidate shortlist css
.short__list__candidate {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.shortlist__action {
    display: flex;
    gap: 20px;
    align-items: center;
    @include mq($xxl){
        gap: 10px;
    }
    @include mq($xl){
        gap: 10px;
    }
    @include mq($sm){
        flex-wrap: wrap;
        gap: 15px;
    }
    .action__item {
        position: relative;
        height: 42px;
        width: 42px;
        display: grid;
        place-items: center;
        border: 1px solid var(--rts-border);
        border-radius: 6px;
        transition: var(--transition);

        &:hover {
            border-color: var(--rts-button-1);
            background-color: var(--rts-button-1);

            svg path[fill] {
                fill: var(--rts-white);
            }

            svg path[stroke] {
                stroke: var(--rts-white);
            }
        }

        .notification__item {
            height: 14px;
            width: 14px;
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: var(--rts-button-1);
            border-radius: 50%;
            display: grid;
            place-items: center;
            color: var(--rts-white);
            font-size: 6px;
        }
    }

    .action__item__long {
        border: 1px solid var(--rts-border);
        border-radius: 6px;
        transition: var(--transition);
        padding: 12px 15px;
        display: flex;
        gap: 10px;
        color: var(--rts-para);
        font-weight: 500;
        @include mq($sm){
            font-size: 16px;
        }
        &:hover {
            background-color: var(--rts-button-1);
            color: var(--rts-white);

            svg path[fill] {
                fill: var(--rts-white);
            }

            svg path[stroke] {
                stroke: var(--rts-white);
            }
        }
    }
}

.single__shortlist__item {
    display: flex;
    justify-content: space-between;
    background-color: var(--rts-white);
    padding: 30px;
    border-radius: 16px;
    flex-wrap: wrap;
    gap: 30px;
    @include mq($xxl){
        gap: 10px;
    }
    @include mq($xl){
        font-size: 16px;
        gap: 15px;
    }
    @include mq($sm){
        gap: 20px;
    }
    .author__info {
        display: flex;
        gap: 60px;
        align-items: center;
        @include mq($xxl){
            gap: 20px;
        }
        @include mq($xl){
            gap: 15px;
        }
        @include mq($lg){
            gap: 40px;
        }
        @include mq($sm){
            gap: 20px;
            flex-wrap: wrap;
        }
    }

    .author__meta {
        display: flex;
        gap: 30px;
        align-items: center;
        @include mq($xxl){
            gap: 20px;
        }
        @include mq($xl){
            gap: 15px;
        }
        @include mq($xs){
            flex-wrap: wrap;
            gap: 20px;
        }
        @include mq($sm){
            gap: 15px;
        }
        .author__image {
            height: 92px;
            width: 92px;
            border-radius: 6px;

            img {
                height: 100%;
                width: 100%;
                border-radius: 6px;
            }
        }
        .author__name{
            h6{
                @include mq($xxl){
                    font-size: 22px;
                }
                @include mq($xl){
                    font-size: 22px;
                }
            }
        }
    }

    .author__info__list {
        display: flex;
        gap: 60px;

        @include mq($xl) {
            gap: 15px;
        }
        @include mq($sm){
            font-size: 15px;
            gap: 30px;
        }
        @include mq($xs){
            flex-wrap: wrap;
            gap: 20px;
        }
        
        span {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
}

// employer action click
.action__click {
    background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
    color: var(--rts-button-1);
    transition: var(--transition);
    padding: 12px 18px;
    line-height: 1.2;
    border-radius: 6px;
}

// candidate shortlist
.candidate__filter__area{
    display: flex;
    justify-content: space-between;
    background-color: var(--rts-white);
    align-items: center;
    padding: 30px;
    border-radius: 16px;
    margin-bottom: 30px;
    @include mq($sm){
        flex-direction: column;
        align-items: start;
        gap: 20px;
        justify-content: start;
    }
}
.candidate__filter{
    width: 40%;
    @include mq($lg){
        width: 60%;
    }
    @include mq($sm){
        width: 100%;
    }
}
.candidate__filter__shorting{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    button{
        font-weight: 500;
        &.active{
            color: var(--rts-button-3);
            font-weight: 600;
        }
    }
}