.rts__brand {
    position: relative;
    bottom: 60px;
    margin-bottom: -60px;
    .bg-white {
        box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
        border-radius: 10px;
        padding: 30px 35px;
    }
    &.style__none{
        bottom: 0;
        margin-bottom: 0;
    }
}

.a{
box-shadow: 0px 4px 40px rgba(73, 73, 73, 0.1);

}