// nav menu style
.navigation {
    &__menu {
      ul {
        display: flex;
        max-width: max-content;
        position: relative;
        padding: 0;
        margin: 0;
      }
  
      &--item {
        position: relative;
        margin: 0 20px;
        @include mq($xl){
          margin: 0 25px;
        }
        @media screen and (max-width: 1300px) and (min-width: 1200px) {
          margin: 0 15px;
        }
        @include mq($lg){
          margin: 0 14px;
        }
        &:last-child {
          margin-right: 0;
          &::after{
            display: none;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:hover > a {
          color: var(--rts-heading) !important;
        }
        &::after {
         @extend %position-style;
          height: 2px;
          width: 2px;
          border-radius: 50px;
          background: var(--gray-1);
          top: 50%;
          right: -31px;
        }
  
        &__link {
          position: relative;
          font-size: 16px;
          text-transform: capitalize;
          padding: 35px 0;
          font-weight: 500;
          display: block;
          color: var(--rts-heading);
          transition: padding 0.3s ease;
        }
  
        &.has-child {
          &:hover {
            ul.submenu {
              opacity: 1;
              visibility: visible;
              transform: scaleY(1);
  
              li.has-child {
                &:hover {
                  ul {
                    opacity: 1;
                    visibility: visible;
                    transform: scaleY(1);
                  }
                }
              }
            }
          }
        }
  
        &.has-arrow {
          position: relative;
          &::before {
            position: absolute;
            content: "\f078";
            font-family: var(--fontawesome-pro);
            top: 50%;
            right: -15px;
            transform: translateY(-50%);
            color: var(--rts-heading);
            font-size: 12px;
            transition: var(--transition);
          }
          &:hover {
            &::before {
              content: "\f077";
              font-family: var(--fontawesome-pro);
              transition: var(--transition);
              color: var(--rts-button-1);
            }
          }
        }
  
        ul.submenu,
        .sub__style {
          position: absolute;
          top: 100%;
          min-width: 230px;
          background: var(--rts-white);
          z-index: 1024;
          display: inline-block;
          opacity: 0;
          padding: 20px 0 30px 0;
          transform: scaleY(0);
          transform-origin: top center;
          visibility: hidden;
          transition: var(--transition);
          left: 0;
          box-shadow: 0 36px 35px rgba(0,0,0,.08);
          li {
            position: relative;
            display: block;
            padding: 0 30px;
            &:last-child {
              margin-bottom: 0;
            }
            &:hover > a {
              color: var(--rts-heading);
  
              &::before {
                width: 80%;
              }
            }
  
            &:not(:last-child) {
              margin: 0 0 10px;
            }
            &.has-arrow {
              position: relative;
              &::before {
                position: absolute;
                content: "\f054";
                font-family: var(--fontawesome-pro);
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
                color: var(--rts-heading);
                font-size: 14px;
                transition: var(--transition);
              }
              &:hover {
                &::before {
                  content: "\f054";
                  font-family: var(--fontawesome-pro);
                  transition: var(--transition);
                }
              }
            }
            a {
              padding: 0;
              font-size: 15px;
              text-transform: capitalize;
              font-weight: 500;
              position: relative;
              color: var(--rts-heading);
  
              &::before {
                left: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                @extend %position-style;
                background: var(--rts-heading);
                transition: var(--transition);
              }
  
              &:hover {
                &::before {
                  width: 100%;
                }
              }
            }
  
            ul {
              top: 0;
              position: absolute;
              left: 100%;
              visibility: hidden;
              transform: scaleY(0);
              transform-origin: top center;
              opacity: 0;
              transition: var(--transition);
            }
          }
        }
      }
    }
  }
  
  // sticky header
  .header__sticky {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    box-shadow: none;
    background: transparent;
    z-index: 999;
    margin: auto;
    align-items: center;
    left: 0;
    border-radius: 0;
    transition: all 0.5s ease-in-out;
    top: 0;
    transform: translateY(0);
    &.fixed {
      position: fixed !important;
      box-shadow: 0px 7px 18px #1810100d;
      background: var(--rts-white);
    }
  }

  
  