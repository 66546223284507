:root{

    /* Text Color */
    --rts-para: #7D8087;
    --rts-black: #000000;
    --rts-heading: #0B0D28;
    --rts-white: #FFFFFF;
    --rts-gray: #F1F1F1;
    --rts-primary: #34A853;

    /* Background Color */
    --rts-button-1: #34A853;
    --rts-button-2: #F9AB00;
    --rts-button-3: #0B0D28;
    --rts-border: rgba(125, 128, 135, 0.3);

    
    --transition: all .4s ease;
    --color-success: #26CF4B;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;


    /* typhography */
    --font: "Plus Jakarta Sans", sans-serif;
    --fontawesome-pro: "Font Awesome 6 Pro";
    --h1: 80px;
    --h2: 60px;
    --h3: 48px;
    --h4: 40px;
    --h5: 32px;
    --h6: 24px;
    --20: 20px;
    --p: 18px;
    --p-sm: 16px;
    --p-xs: 14px;
}
