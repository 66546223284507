@charset "UTF-8";
/** This is main css file of template. You can customize this file with your own
 * styles. To learn more about SASS variables visit http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html
 */
/*========

==== Default ====
1. Typography
2. Variable
3. Animation
4. input
5. Spacing

==== Mixin ====
6. Mixin

=========*/
:root {
  /* Text Color */
  --rts-para: #7D8087;
  --rts-black: #000000;
  --rts-heading: #0B0D28;
  --rts-white: #FFFFFF;
  --rts-gray: #F1F1F1;
  --rts-primary: #34A853;
  /* Background Color */
  --rts-button-1: #34A853;
  --rts-button-2: #F9AB00;
  --rts-button-3: #0B0D28;
  --rts-border: rgba(125, 128, 135, 0.3);
  --transition: all .4s ease;
  --color-success: #26CF4B;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  /* typhography */
  --font: "Plus Jakarta Sans", sans-serif;
  --fontawesome-pro: "Font Awesome 6 Pro";
  --h1: 80px;
  --h2: 60px;
  --h3: 48px;
  --h4: 40px;
  --h5: 32px;
  --h6: 24px;
  --20: 20px;
  --p: 18px;
  --p-sm: 16px;
  --p-xs: 14px;
}

/**
 * Modern CSS Reset Tweaks
 * ================================================== */
html {
  -webkit-text-size-adjust: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-size-adjust: 100%;
  position: relative;
  width: 100%;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/* Box sizing normalization */
*,
::after,
::before {
  box-sizing: border-box;
}

/* Elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/**
 * CSS Reset Tweaks
 *
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0-modified | 20110126
 * License: none (public domain)
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/**
 * Input Reset
 */
input:required,
input {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:focus {
  outline: none;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 */
[hidden] {
  display: none;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  height: auto;
}

/* Make pictures easier to work with */
picture {
  display: inline-block;
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  border: 0;
  background: transparent;
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

[disabled] {
  pointer-events: none;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 */
input[type=checkbox],
input[type=radio] {
  padding: 0;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  border: 0;
  background: transparent;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

/**
 * Based on normalize.css v8.0.1
 * github.com/necolas/normalize.css
 */
hr {
  box-sizing: content-box;
  overflow: visible;
  background: #000;
  border: 0;
  height: 1px;
  line-height: 0;
  margin: 0;
  padding: 0;
  page-break-after: always;
  width: 100%;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 */
pre {
  font-family: monospace, monospace;
  font-size: 100%;
}

/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 75%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -5px;
}

sup {
  top: -5px;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
  padding: 0;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
  outline: 0;
}

legend {
  color: inherit;
  white-space: normal;
  display: block;
  border: 0;
  max-width: 100%;
  width: 100%;
}

fieldset {
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: block;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Misc
 * ========================================================================== */
template {
  display: none;
}

.job__cta__card .cta__content::after, .rts__image__section, .breadcrumb__background, .footer__home__four::after, .footer__home__four, .footer__home__three, .footer__home__two, .footer__home__one, .funfact__bg, .rts__testimonial__background, .home__six__banner, .home__three__banner, .home__one__banner .rts__banner__background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navigation__menu--item ul.submenu li a::before,
.navigation__menu--item .sub__style li a::before, .navigation__menu--item::after {
  position: absolute;
  content: "";
}

/*-----------------
    Typography
-------------------*/
body {
  font-size: var(--p);
  line-height: 28px;
  font-weight: 400;
  font-family: var(--font);
  color: var(--rts-para);
  overflow-x: hidden;
  margin: 0 auto;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
}

a, a:hover, a:focus {
  text-decoration: none;
}

.font-xs {
  font-size: var(--p-xs);
}

.font-sm {
  font-size: 16px;
}

.rts__white {
  color: var(--rts-white);
}

.text-gray {
  color: var(--rts-gray);
}

h1, .h1 {
  font-size: var(--h1);
  line-height: 90px;
}
@media screen and (max-width: 992px) {
  h1, .h1 {
    font-size: 70px;
    line-height: 80px;
  }
}

h2, .h2 {
  font-size: var(--h2);
  line-height: 70px;
}
@media screen and (max-width: 1200px) {
  h2, .h2 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (max-width: 992px) {
  h2, .h2 {
    font-size: 48px;
    line-height: 58px;
  }
}
@media screen and (max-width: 768px) {
  h2, .h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

h3, .h3 {
  font-size: var(--h3);
  line-height: 58px;
}
@media screen and (max-width: 1400px) {
  h3, .h3 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (max-width: 992px) {
  h3, .h3 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (max-width: 768px) {
  h3, .h3 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (max-width: 576px) {
  h3, .h3 {
    font-size: 25px;
    line-height: 35px;
  }
}

h4, .h4 {
  font-size: var(--h4);
  line-height: 50px;
}
@media screen and (max-width: 992px) {
  h4, .h4 {
    font-size: 30px;
    line-height: 40px;
  }
}

h5, .h5 {
  font-size: var(--h5);
  line-height: 42px;
}
@media screen and (max-width: 992px) {
  h5, .h5 {
    font-size: 20px;
    line-height: 30px;
  }
}

h6, .h6 {
  font-size: var(--h6);
  line-height: 30px;
}
@media screen and (max-width: 1400px) {
  h6, .h6 {
    font-size: 20px;
  }
}
@media screen and (max-width: 576px) {
  h6, .h6 {
    font-size: 20px;
    line-height: 30px;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--rts-heading);
  font-weight: 700;
}

p {
  font-size: var(--p);
}

.font-20 {
  font-size: 20px;
}
@media screen and (max-width: 576px) {
  .font-20 {
    font-size: 18px;
  }
}

.text-dark {
  color: var(--rts-heading) !important;
}

.text-body {
  color: var(--rts-para) !important;
}

.list-style-dot li {
  list-style: none;
  padding-left: 30px;
  margin-bottom: 15px;
  font-weight: 500;
  position: relative;
}
.list-style-dot li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--rts-button-1);
  content: "";
}

.rts__section {
  position: relative;
}

.mtn-1 {
  margin-top: -10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.my-40 {
  margin: 40px 0;
}

.my-30 {
  margin: 30px 0;
}

.section__mb {
  margin-bottom: 13px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-100 {
  margin-left: 100px;
}

.pt--10 {
  top: -10px;
}

.pt-30 {
  padding-top: 30px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-100 {
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .pt-100 {
    padding-top: 60px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .pb-100 {
    padding-bottom: 60px;
  }
}

.pt-110 {
  padding-top: 110px;
}
@media screen and (max-width: 992px) {
  .pt-110 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .pt-110 {
    padding-top: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media screen and (max-width: 992px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

.py-135 {
  padding-top: 135px;
  padding-bottom: 135px;
}

.pt-120 {
  padding-top: 120px;
}
@media screen and (max-width: 1200px) {
  .pt-120 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 992px) {
  .pt-120 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .pt-120 {
    padding-top: 60px;
  }
}

.pt-260 {
  padding-top: 260px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pb-200 {
  padding-bottom: 200px;
}

.gap-30 {
  --bs-gutter-x: 30px;
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.row-30 {
  row-gap: 30px;
}

.row-35 {
  row-gap: 35px;
}

.section__padding {
  padding-top: 110px;
  padding-bottom: 120px;
}
@media screen and (max-width: 992px) {
  .section__padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .section__padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.max-contnet {
  max-width: max-content;
}

.max-content {
  max-width: max-content;
}

.g-30 {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 30px;
}

.max-630 {
  max-width: 630px;
}

.max-750 {
  max-width: 750px;
}

.max-720 {
  max-width: 720px;
}

.max-695 {
  max-width: 695px;
}

.max-360 {
  max-width: 360px;
}

.mnw-500 {
  min-width: 500px;
}

.top-30 {
  top: 30px;
}

.top-25 {
  top: 25px !important;
}

.end-30 {
  right: 30px;
}

.end--30 {
  right: -30px;
}

.left-20 {
  left: 20px;
}

.padding-75 {
  padding: 0 75px;
}
@media screen and (max-width: 1200px) {
  .padding-75 {
    padding: 0 30px;
  }
}

.gap-20 {
  gap: 20px;
}

.gap-40 {
  gap: 40px;
}

.gap-30 {
  gap: 30px;
}

.gap-50 {
  gap: 50px;
}

.gap-60 {
  gap: 60px;
}

.no-bb {
  bottom: 0 !important;
  margin-bottom: 0 !important;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  background: var(--rts-white);
  width: 50%;
  height: 100%;
  z-index: 1000;
}

.loader-wrapper .loader-section.section-left {
  left: 0;
}

.loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded Styles */
.loaded .loader-wrapper .loader-section.section-left {
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

.loaded .loader-wrapper .loader-section.section-right {
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.loaded .loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}

.loader:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  bottom: 14px;
  border: 4px solid transparent;
  border-top-color: var(--rts-primary);
  border-right-color: var(--rts-primary);
  border-bottom-color: var(--rts-primary);
  border-radius: 100%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loader {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  z-index: 1001;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animated {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.flipOutX, .animated.flipOutY, .animated.bounceIn, .animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(10%, 0, 0) skewX(-30deg);
    transform: translate3d(10%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(10%, 0, 0) skewX(-30deg);
    transform: translate3d(10%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(10%, 0, 0) skewX(30deg);
    transform: translate3d(10%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(10%, 0, 0) skewX(30deg);
    transform: translate3d(10%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.text-primary {
  color: var(--rts-primary) !important;
}

.l--1 {
  letter-spacing: -1px;
}
@media screen and (max-width: 768px) {
  .l--1 {
    letter-spacing: 0;
  }
}

.l--3 {
  letter-spacing: -2px;
}
@media screen and (max-width: 768px) {
  .l--3 {
    letter-spacing: 0;
  }
}

.focus-none:focus {
  outline: none;
  box-shadow: none;
}

@media only screen and (max-width: 1199px) {
  .br-lg-none br {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .br-md-none br {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .br-sm-none br {
    display: none;
  }
}

.rounded-50 {
  border-radius: 50px !important;
}

.d-shadow {
  filter: drop-shadow(0px 10px 30px rgba(153, 153, 153, 0.25));
}

.text-para {
  color: var(--rts-para);
}

.list__style__dot li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 25px;
}
.list__style__dot li:last-child {
  margin-bottom: 0;
}
.list__style__dot li::before {
  position: absolute;
  left: 0;
  top: 20%;
  transform: translate(0, -20%);
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--rts-para);
  content: "";
}

.number__style__dot {
  padding-left: 15px;
}
.number__style__dot li {
  list-style: number;
  margin-bottom: 25px;
}
.number__style__dot li:last-child {
  margin-bottom: 0;
}

.min-fit-content {
  min-width: fit-content;
}

.bg-primary {
  background: var(--rts-primary) !important;
  color: var(--rts-white) !important;
}

.pointer {
  cursor: pointer;
}

a[savefrom_lm="1"][savefrom_lm_is_link="1"] {
  display: none !important;
  background-image: none !important;
  position: absolute !important;
}

.animated {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.flipOutX, .animated.flipOutY, .animated.bounceIn, .animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(10%, 0, 0) skewX(-30deg);
    transform: translate3d(10%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(10%, 0, 0) skewX(-30deg);
    transform: translate3d(10%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(10%, 0, 0) skewX(30deg);
    transform: translate3d(10%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(10%, 0, 0) skewX(30deg);
    transform: translate3d(10%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

select {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
}

.nice-select {
  width: 100%;
  height: auto;
  font-size: inherit;
  position: relative;
}
.nice-select.open::before {
  content: "";
  font-family: var(--fontawesome-pro);
  transition: var(--transition);
}
.nice-select ul.list {
  width: 100%;
}
.nice-select ul.list li.selected {
  font-weight: 500;
}
.nice-select:focus {
  border-color: transparent;
}
.nice-select::before {
  position: absolute;
  content: "";
  font-family: var(--fontawesome-pro);
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: var(--rts-heading);
  font-size: 12px;
  transition: var(--transition);
}
.nice-select::after {
  border-bottom: 0;
  border-right: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--rts-button-1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--rts-button-1);
}

input:checked + .slider:before {
  transform: translateX(30px);
}

.newsletter {
  position: relative;
  max-width: 310px;
}
@media screen and (max-width: 576px) {
  .newsletter {
    max-width: 100%;
  }
}
.newsletter input {
  position: relative;
  padding: 20px 18px;
  padding-right: 120px;
  border-radius: 10px;
  border: 0;
  width: 100%;
}
.newsletter button {
  position: absolute;
  right: 15px;
  padding: 10px;
  border-radius: 6px;
  font-size: 16px;
}

.single__input {
  position: relative;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
}
.checkbox label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--rts-button-1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 10px;
  top: -2px;
}

.checkbox input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid var(--rts-white);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.checkbox input:checked + label::before {
  background-color: var(--rts-button-1);
  border-radius: 2px;
}

textarea::placeholder, input::placeholder {
  color: var(--rts-para) !important;
}

.job__contact.is__contact {
  background: transparent;
  padding: 0;
}
.job__contact.is__contact input, .job__contact.is__contact textarea {
  border: 1px solid var(--rts-border) !important;
}

/* ====== Header ====== */
.rts__menu__background {
  background-color: var(--rts-white);
  max-width: 1410px;
  padding: 0 45px;
  border-radius: 0 0 30px 30px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .rts__menu__background {
    padding: 0 20px;
    border-radius: 0;
  }
}
@media screen and (max-width: 992px) {
  .rts__menu__background {
    padding: 20px 15px;
    border-radius: 0;
  }
}

.header__right__btn button {
  padding: 8px 20px;
  border: 1px solid var(--rts-button-1);
  border-radius: 4px;
  transition: var(--transition);
}
.header__right__btn button:hover {
  background: var(--rts-button-1);
  color: var(--rts-white);
}
.header__right__btn button i {
  font-size: 20px;
}
@media screen and (max-width: 1200px) {
  .header__right__btn.gap-30 {
    gap: 20px;
  }
}

.absolute__header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.header__style__two {
  background-color: var(--rts-white);
  margin: 30px 60px;
  border-radius: 50px;
  width: 94%;
}
@media screen and (max-width: 992px) {
  .header__style__two {
    padding: 30px 0;
  }
}

.rts__header {
  transition: all 0.3s ease;
}
.rts__header.sticky {
  position: fixed;
  animation: stickyanimations 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  transition: padding 1s;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  background: var(--rts-white);
  z-index: 999;
  width: inherit;
}
.rts__header.sticky .navigation__menu--item__link {
  padding: 30px 0;
}

@keyframes stickyanimations {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@media screen and (min-width: 1400px) and (max-width: 1670px) {
  .header__style__two {
    margin: 30px 40px;
  }
}
@media screen and (max-width: 1400px) {
  .header__style__two {
    margin: 30px 30px;
  }
}
@media screen and (max-width: 768px) {
  .header__style__two {
    margin: 0 auto;
    width: 100%;
    border-radius: 0;
  }
}
.header__style__two.sticky {
  width: 94%;
}
@media screen and (max-width: 768px) {
  .header__style__two.sticky {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .notification__btn {
    display: none;
  }
}

/*======= Banner Home One =======*/
.home__one__banner {
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .home__one__banner {
    padding-top: 150px;
  }
}
.home__one__banner .rts__banner__background {
  background-image: url("../img/home-1/banner/header__background.svg");
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.home__one__banner .rts__banner__background .__first {
  position: absolute;
  left: -3%;
  top: 0;
}
.home__one__banner .rts__banner__background .__second {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.home__one__banner .rts__banner__content {
  position: relative;
  z-index: 1;
  max-width: 675px;
}
.home__one__banner .rts__banner__content .rts__banner__title {
  letter-spacing: -1px;
}
@media screen and (max-width: 1400px) {
  .home__one__banner .rts__banner__content .rts__banner__title {
    font-size: 75px;
  }
}
@media screen and (max-width: 1200px) {
  .home__one__banner .rts__banner__content .rts__banner__title {
    font-size: 55px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 576px) {
  .home__one__banner .rts__banner__content .rts__banner__title {
    text-align: center;
    font-size: 40px;
    line-height: 1.2;
  }
}
.home__one__banner .rts__banner__content .rts__banner__title span {
  color: var(--rts-button-1);
}
@media screen and (max-width: 576px) {
  .home__one__banner .rts__banner__content .rts__banner__desc {
    text-align: center;
  }
}
@media screen and (max-width: 992px) {
  .home__one__banner .rts__banner__wrapper {
    flex-wrap: wrap;
    gap: 180px !important;
  }
}
.home__one__banner .rts__banner__image {
  margin-top: -90px;
  z-index: 1;
}
.home__one__banner .rts__banner__image .banner__image__shape {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .home__one__banner .rts__banner__image .banner__image__shape {
    display: none;
  }
}
.home__one__banner .rts__banner__image .banner__image__shape .facebook, .home__one__banner .rts__banner__image .banner__image__shape .linkedin, .home__one__banner .rts__banner__image .banner__image__shape .twitter {
  height: 62px;
  width: 62px;
  border-radius: 6px;
  background-color: var(--rts-white);
  font-size: 30px;
  display: grid;
  place-items: center;
  color: #7763e5;
  position: absolute;
  top: 0;
  left: 25%;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.home__one__banner .rts__banner__image .banner__image__shape .twitter {
  color: var(--rts-button-1);
  position: absolute;
  top: 22%;
  left: 5%;
}
.home__one__banner .rts__banner__image .banner__image__shape .linkedin {
  color: var(--rts-button-3);
  position: absolute;
  top: 28%;
  left: 88%;
}

.rts__job__search {
  background: #FFFFFF;
  box-shadow: 0px 30px 30px rgba(175, 175, 175, 0.16);
  border-radius: 16px;
  padding: 22px 20px;
  position: relative;
  z-index: 99;
}
.rts__job__search .single__input {
  min-width: 190px;
}
@media screen and (max-width: 1400px) {
  .rts__job__search .single__input {
    min-width: 170px;
  }
}
@media screen and (max-width: 768px) {
  .rts__job__search .single__input {
    min-width: 100%;
  }
}
.rts__job__search .single__input label {
  font-size: 20px;
  font-weight: 500;
  color: var(--rts-heading);
  margin-bottom: 15px;
  text-transform: capitalize;
  letter-spacing: -1px;
  line-height: 1;
}
.rts__job__search .single__input .nice-select {
  border: none;
  padding: 0;
  font-size: 18px;
  height: auto;
  line-height: 1;
}
.rts__job__search .single__input .nice-select.open::before {
  content: "";
  font-family: var(--fontawesome-pro);
  transition: var(--transition);
}
.rts__job__search .single__input .nice-select::after {
  border-bottom: 0;
  border-right: 0;
}
.rts__job__search .single__input .nice-select::before {
  position: absolute;
  content: "";
  font-family: var(--fontawesome-pro);
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: var(--rts-heading);
  font-size: 12px;
  transition: var(--transition);
}
.rts__job__search .job__search {
  min-width: 160px;
  height: 60px;
  text-align: center;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .rts__job__search .job__search {
    height: 55px;
  }
}

/*======= Banner Home Two =======*/
.home__two__banner {
  position: relative;
  background-image: url("../img/home-2/header.webp");
  padding-bottom: 250px;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 992px) {
  .home__two__banner {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
.home__two__banner .rts__job__search {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  .home__two__banner .rts__job__search {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .home__two__banner .rts__job__search.rounded-50 {
    border-radius: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .home__two__banner .rts__banner__title {
    font-size: 50px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 576px) {
  .home__two__banner .rts__banner__title {
    font-size: 40px;
    line-height: 1.3;
  }
}

/*======= Banner Home Three =======*/
.home__three__banner {
  background-image: url("../img/home-3/banner/home_three.png");
  padding-bottom: 140px;
  padding-top: 130px;
}
@media screen and (max-width: 768px) {
  .home__three__banner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.home__three__banner .rts__banner__title {
  color: var(--rts-white);
  letter-spacing: -3px;
}
@media screen and (max-width: 576px) {
  .home__three__banner .rts__banner__title {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 0;
  }
}
.home__three__banner p {
  color: var(--rts-white);
}
.home__three__banner .rts__banner__image {
  z-index: 1;
}
.home__three__banner .rts__banner__image .banner__image__shape {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.home__three__banner .rts__banner__image .banner__image__shape .facebook, .home__three__banner .rts__banner__image .banner__image__shape .linkedin, .home__three__banner .rts__banner__image .banner__image__shape .twitter {
  height: 62px;
  width: 62px;
  border-radius: 6px;
  background-color: var(--rts-white);
  font-size: 30px;
  display: grid;
  place-items: center;
  color: #7763e5;
  position: absolute;
  top: 5%;
  left: 18%;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) {
  .home__three__banner .rts__banner__image .banner__image__shape .facebook, .home__three__banner .rts__banner__image .banner__image__shape .linkedin, .home__three__banner .rts__banner__image .banner__image__shape .twitter {
    height: 55px;
    width: 55px;
    font-size: 25px;
  }
}
.home__three__banner .rts__banner__image .banner__image__shape .twitter {
  color: var(--rts-button-1);
  position: absolute;
  top: 38%;
  left: 5%;
}
.home__three__banner .rts__banner__image .banner__image__shape .linkedin {
  color: var(--rts-button-3);
  position: absolute;
  top: 30%;
  left: 80%;
}

.current__job {
  background-color: var(--rts-white);
  border-radius: 10px;
  padding: 15px 20px;
  max-width: max-content;
  align-items: center;
  position: absolute;
  bottom: 50px;
  right: -50px;
}
@media screen and (max-width: 576px) {
  .current__job {
    right: 0;
  }
}

.rts__icon {
  height: 64px;
  width: 64px;
  background-color: #F4F6F5;
  display: grid;
  place-items: center;
  border-radius: 4px;
}

.rts__text span {
  color: var(--rts-button-3);
  display: block;
}

.rts__job__search.form-2 {
  background: #FFFFFF;
  box-shadow: unset;
  border-radius: 16px;
  padding: 10px 20px;
  box-shadow: 0px 30px 30px rgba(20, 22, 55, 0.1);
}
@media screen and (max-width: 1200px) {
  .rts__job__search.form-2 {
    padding: 20px;
  }
}
.rts__job__search.form-2 .single__input {
  max-width: 230px;
  min-width: 230px;
}
@media screen and (max-width: 768px) {
  .rts__job__search.form-2 .single__input {
    min-width: 100%;
  }
}
.rts__job__search.form-2 .single__input input {
  padding: 12px 20px 12px 40px;
  border-radius: 6px;
  background: var(--rts-gray);
  border: none;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .rts__job__search.form-2 .single__input input {
    font-size: 16px;
  }
}
.rts__job__search.form-2 .single__input .search__icon,
.rts__job__search.form-2 .single__input .location__icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: var(--rts-heading);
}
.rts__job__search.form-2 .single__input .nice-select {
  padding: 14px 20px 14px 40px;
  border-radius: 6px;
  background: var(--rts-gray);
  border: none;
  line-height: 1;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .rts__job__search.form-2 .single__input .nice-select {
    font-size: 16px;
  }
}
.rts__job__search.form-2 .single__input .nice-select.open::before {
  content: "";
  font-family: var(--fontawesome-pro);
  transition: var(--transition);
}
.rts__job__search.form-2 .single__input .nice-select::after {
  border-bottom: 0;
  border-right: 0;
}
.rts__job__search.form-2 .single__input .nice-select::before {
  position: absolute;
  content: "";
  font-family: var(--fontawesome-pro);
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: var(--rts-heading);
  font-size: 12px;
  transition: var(--transition);
}
.rts__job__search.form-2 .job__search {
  min-width: 160px;
  height: 50px;
  text-align: center;
  border-radius: 10px;
}

/* ======= Banner Home Four =======*/
.home__four__banner {
  background-color: var(--rts-gray);
  background-image: url("../img/hom-4/banner/header__four__bg.svg");
}
@media screen and (max-width: 992px) {
  .home__four__banner .rts__banner__wrapper.pb-200 {
    padding-bottom: 100px;
  }
}
.home__four__banner .rts__banner__content {
  min-width: 730px;
}
@media screen and (max-width: 1400px) {
  .home__four__banner .rts__banner__content {
    min-width: 670px;
  }
}
@media screen and (max-width: 1200px) {
  .home__four__banner .rts__banner__content {
    min-width: 500px;
  }
}
@media screen and (max-width: 992px) {
  .home__four__banner .rts__banner__content {
    min-width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .home__four__banner .rts__banner__title {
    font-size: 75px;
  }
}
@media screen and (max-width: 1200px) {
  .home__four__banner .rts__banner__title {
    font-size: 55px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 576px) {
  .home__four__banner .rts__banner__title {
    font-size: 45px;
  }
}
.home__four__banner .rts__banner__image figure.banner__image {
  height: 530px;
  width: 730px;
}
@media screen and (max-width: 1400px) {
  .home__four__banner .rts__banner__image figure.banner__image {
    width: 670px;
  }
}
@media screen and (max-width: 992px) {
  .home__four__banner .rts__banner__image figure.banner__image {
    width: 100%;
    height: 100%;
  }
}

.rts__job__search.form-4 {
  max-width: 665px;
  border-radius: 0;
  padding: 15px 20px;
}
.rts__job__search.form-4 input {
  width: 100%;
  border: 0;
  padding-right: 15px;
}
.rts__job__search.form-4 .job__search {
  border-radius: 0;
  height: 50px;
  min-width: 130px;
}

/* ======= Banner Home Five =======*/
.home__five__banner {
  background-image: url("../img/home-5/banner/banner__background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 160px;
}
@media screen and (max-width: 1200px) {
  .home__five__banner {
    border-bottom-left-radius: 0;
  }
}
@media screen and (max-width: 1200px) {
  .home__five__banner .pt-150 {
    padding-top: 100px;
  }
}
.home__five__banner .rts__banner__content {
  min-width: 750px;
}
@media screen and (max-width: 992px) {
  .home__five__banner .rts__banner__content {
    min-width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .home__five__banner .rts__banner__title {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 992px) {
  .home__five__banner .rts__banner__title {
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  .home__five__banner .rts__banner__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .home__five__banner .rts__banner__title {
    font-size: 45px;
    line-height: 1.2;
  }
}
.home__five__banner .rts__banner__desc {
  color: var(--rts-gray);
}
@media screen and (max-width: 1400px) {
  .home__five__banner .rts__banner__desc {
    max-width: 700px;
  }
}
.home__five__banner .rts__job__search.form-2 {
  box-shadow: 0px 30px 30px rgba(20, 22, 55, 0.1);
  padding: 15px;
}
@media screen and (max-width: 1200px) {
  .home__five__banner .rts__banner__wrapper {
    margin-bottom: 80px;
  }
}
.home__five__banner .rts__banner__image {
  margin-bottom: -160px;
  margin-top: -35px;
}
.home__five__banner .rts__banner__image img {
  max-width: 900px;
  height: 600px;
  border-radius: 16px;
  border-bottom-right-radius: 140px;
}
@media screen and (max-width: 1400px) {
  .home__five__banner .rts__banner__image img {
    max-width: 850px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1200px) {
  .home__five__banner .rts__banner__image img {
    max-width: 100%;
    border-bottom-right-radius: 16px;
  }
}
@media screen and (max-width: 992px) {
  .home__five__banner .rts__banner__image img {
    height: 450px;
  }
}
.home__five__banner .applicant {
  bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .home__five__banner .applicant {
    position: relative !important;
    bottom: 0;
    margin-top: 50px;
  }
}
.home__five__banner .rts__banner__shape {
  position: absolute;
  height: 100%;
  width: 100%;
}
.home__five__banner .rts__banner__shape .shape__one,
.home__five__banner .rts__banner__shape .shape__two {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background: linear-gradient(180deg, #58D379 0%, #44B964 100%);
}
.home__five__banner .rts__banner__shape .shape__one {
  position: absolute;
  left: -10%;
  bottom: 40%;
}
.home__five__banner .rts__banner__shape .shape__two {
  position: absolute;
  right: 25%;
  top: 15%;
}
.home__five__banner .rts__banner__shape .shape__three {
  position: absolute;
  bottom: 20%;
  left: 15%;
}
@media screen and (max-width: 1400px) {
  .home__five__banner .rts__banner__shape .shape__three {
    left: 10%;
  }
}
@media screen and (max-width: 1200px) {
  .home__five__banner .rts__banner__shape .shape__three {
    display: none;
  }
}

/* ====== Banner Home Six ======= */
.home__six__banner {
  padding-top: 180px;
  padding-bottom: 150px;
  background-image: url("../img/home-6/banner/banner__bg.png");
  margin: 0 65px;
  position: relative;
}
@media screen and (max-width: 992px) {
  .home__six__banner {
    margin: 0;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .home__six__banner {
    padding-top: 70px;
  }
}
.home__six__banner * > {
  position: relative;
  z-index: 1;
}
.home__six__banner .rounded-full {
  border-radius: 125px;
  position: relative;
  top: -30px;
}
@media screen and (max-width: 576px) {
  .home__six__banner .rounded-full {
    top: 0;
    margin-bottom: 20px;
    border-radius: 4px;
  }
}
.home__six__banner .rts__banner__shape {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.home__six__banner .rts__banner__shape .shape {
  height: 60px;
  width: 60px;
  place-items: center;
  display: grid;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: var(--rts-white);
}
@media screen and (max-width: 992px) {
  .home__six__banner .rts__banner__shape .shape {
    display: none;
  }
}
.home__six__banner .rts__banner__shape .shape__one {
  position: absolute;
  left: 35%;
  top: 6%;
}
.home__six__banner .rts__banner__shape .shape__two {
  position: absolute;
  right: 40%;
  top: 6%;
}
.home__six__banner .rts__banner__shape .shape__three {
  position: absolute;
  left: 35%;
  bottom: 25%;
}
.home__six__banner .rts__banner__shape .shape__four {
  position: absolute;
  right: 40%;
  bottom: 25%;
}
.home__six__banner .rts__banner__shape .shape__five {
  position: absolute;
  left: 10%;
  top: 30%;
}
.home__six__banner .rts__banner__shape .shape__six {
  position: absolute;
  right: 10%;
  top: 30%;
}
@media screen and (max-width: 768px) {
  .home__six__banner .rts__banner__title {
    font-size: 55px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 576px) {
  .home__six__banner .rts__banner__title {
    font-size: 35px;
    line-height: 1.2;
  }
}

.rts__brand {
  position: relative;
  bottom: 60px;
  margin-bottom: -60px;
}
.rts__brand .bg-white {
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
  padding: 30px 35px;
}
.rts__brand.style__none {
  bottom: 0;
  margin-bottom: 0;
}

.a {
  box-shadow: 0px 4px 40px rgba(73, 73, 73, 0.1);
}

.featured__bg {
  background-color: var(--rts-button-3);
}

.featured__job {
  color: var(--rts-white);
}
.featured__job .rts__section__title {
  color: var(--rts-white);
}
@media screen and (max-width: 768px) {
  .featured__job .rts__section__title {
    font-size: 35px;
  }
}

.rts__testimonial__background {
  background-image: url("../img/home-1/testimonial/testimonial__bg.png");
}

.rts__single__testimonial .testimonial__text {
  color: var(--rts-para);
  font-weight: 600;
  letter-spacing: -1%;
}
.rts__single__testimonial .author__image img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.testimonial__style__three .testimonial__text {
  font-size: 36px;
  font-weight: 600;
  color: var(--rts-heading);
  line-height: 55px;
}
@media screen and (max-width: 768px) {
  .testimonial__style__three .testimonial__text {
    font-size: 30px;
    line-height: 45px;
  }
}
@media screen and (max-width: 576px) {
  .testimonial__style__three .testimonial__text {
    font-size: 25px;
    line-height: 35px;
  }
}
.testimonial__style__three .slider__btn {
  background-color: var(--rts-gray) !important;
}
.testimonial__style__three .slider__btn:hover {
  background-color: var(--rts-button-3) !important;
  color: var(--rts-white) !important;
}

.rts__single__testimonial.style__four {
  position: relative;
}
.rts__single__testimonial.style__four .testimonial__content {
  position: relative;
  z-index: 1;
  text-align: center;
}
.rts__single__testimonial.style__four .author__image img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.rts__single__testimonial.style__four .rts__rating i {
  color: var(--rts-button-1);
}
.rts__single__testimonial.style__four .author__content span.font-20 {
  color: var(--rts-button-3);
}
.rts__single__testimonial.style__four .author__content p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 120%;
  font-style: normal;
  text-align: left;
  color: var(--rts-para);
}
.rts__single__testimonial.style__four .rts__rating i {
  font-size: 20px;
}

.rts__slider__control.is__home__four__testimonial .slider__btn {
  font-size: 20px;
  height: 50px;
  min-width: 50px;
  background-color: transparent;
}

.client__image img {
  border-radius: 16px;
  border-bottom-right-radius: 160px;
}

@media screen and (max-width: 1200px) {
  .client__testimonial__content h3 {
    font-size: 45px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 992px) {
  .client__testimonial__content h3 {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) {
  .client__testimonial__content h3 {
    font-size: 35px;
  }
}
@media screen and (max-width: 576px) {
  .client__testimonial__content h3 {
    font-size: 30px;
  }
}

.testimonial__content p {
  font-size: 38px;
  line-height: 60px;
  font-weight: 600;
  font-style: italic;
  color: var(--rts-heading);
}
@media screen and (max-width: 1200px) {
  .testimonial__content p {
    font-size: 30px;
    line-height: 1.5;
  }
}
@media screen and (max-width: 768px) {
  .testimonial__content p {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .testimonial__content .rts__rating {
    margin-top: 30px !important;
  }
}
.testimonial__content .rts__rating i {
  color: var(--rts-button-1);
  font-size: 25px;
}

@media screen and (max-width: 992px) {
  .bottom-md-12 {
    bottom: -12%;
  }
}
@media screen and (max-width: 768px) {
  .bottom-md-12 {
    display: none !important;
  }
}

.rts__single__counter {
  position: relative;
}
@media screen and (max-width: 992px) {
  .rts__single__counter {
    text-align: center;
  }
}
.rts__single__counter .heading__color {
  position: relative;
  z-index: 2;
  max-width: max-content;
  margin: 0 auto;
  padding-bottom: 10px;
}
.rts__single__counter .heading__color::before {
  content: "";
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%);
  position: absolute;
  top: 0;
  left: -20px;
  z-index: -1;
}
.rts__single__counter .heading__color.__noround::before {
  display: none;
}
.rts__single__counter .heading__color {
  color: var(--rts-button-1);
}
.rts__single__counter p {
  color: var(--rts-para);
}

.funfact__bg {
  background-image: url("../img/home-3/funfact__bg.png");
}

.funfact__style__two .rts__single__counter {
  text-align: center;
}
.funfact__style__two p {
  color: var(--rts-white);
}
.funfact__style__two .heading__color {
  color: var(--rts-white);
}

.rts__single__counter.style__two {
  background-color: var(--rts-gray);
  text-align: center;
  padding: 50px;
  border-radius: 6px;
}
@media screen and (max-width: 1200px) {
  .rts__single__counter.style__two {
    padding: 40px 5px;
  }
}
.rts__single__counter.style__two .heading__color {
  color: var(--rts-button-2);
  position: relative;
  z-index: 2;
  max-width: max-content;
  margin: 0 auto;
  padding-bottom: 10px;
}
.rts__single__counter.style__two .heading__color::before {
  content: "";
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(226.53deg, #F1F1F1 21.32%, #F9AB00 100%);
  position: absolute;
  top: 0;
  left: -20px;
  z-index: -1;
}

.rts__appcenter {
  background-color: var(--rts-button-1);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  max-height: 430px;
}
@media screen and (max-width: 1200px) {
  .rts__appcenter {
    max-height: 100%;
  }
}
@media screen and (max-width: 992px) {
  .rts__appcenter {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .rts__appcenter {
    border-radius: 0;
  }
}
.rts__appcenter > * {
  position: relative;
  z-index: 1;
}
.rts__appcenter__shape {
  position: absolute;
  top: 0;
  left: 0;
}
.rts__appcenter .content__left {
  padding-left: 120px;
  padding-right: 80px;
}
@media screen and (max-width: 1400px) {
  .rts__appcenter .content__left {
    padding-left: 120px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 992px) {
  .rts__appcenter .content__left {
    padding-left: 0;
    margin: 0 auto;
    padding-right: 0;
  }
}
.rts__appcenter .content__right {
  padding: 90px 80px 90px 0;
}
@media screen and (max-width: 1200px) {
  .rts__appcenter .content__right {
    padding: 90px 0;
    margin: 0 auto;
  }
}
@media screen and (max-width: 992px) {
  .rts__appcenter .content__right p {
    padding: 0 15px;
  }
}
.rts__appcenter .top-15px {
  top: 15px;
}
@media screen and (max-width: 1400px) {
  .rts__appcenter .top-15px {
    top: 40px;
  }
}

.about__image img {
  max-width: 100%;
  height: 650px;
  border-radius: 16px 16px 16px 160px;
}
@media screen and (max-width: 768px) {
  .about__image img {
    height: 100%;
  }
}

.step__icon {
  height: 76px;
  min-width: 76px;
  background-color: var(--rts-gray);
  color: var(--rts-primary);
  font-size: 24px;
  font-weight: 600;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
@media screen and (max-width: 576px) {
  .step__icon {
    height: 60px;
    min-width: 60px;
  }
}

.spacing {
  margin-top: -120px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .spacing {
    margin-top: 0;
  }
}

.about__content {
  padding: 60px 90px 60px 60px;
  box-shadow: 0px 4px 50px rgba(132, 132, 132, 0.16);
  border-radius: 16px 16px 160px 16px;
  min-width: 690px;
  background-color: var(--rts-white);
}
@media screen and (max-width: 1200px) {
  .about__content {
    border-radius: 0;
  }
}
@media screen and (max-width: 768px) {
  .about__content {
    min-width: 100%;
    padding: 60px 50px;
    border-radius: 0;
  }
}
@media screen and (max-width: 576px) {
  .about__content {
    padding: 40px 30px;
  }
}

.about__shape.max-content {
  left: 30%;
  position: absolute;
  z-index: -1;
}

.about__section__bg {
  background-color: #F5F3EA;
}

.shape__image {
  position: absolute;
  left: -50.5%;
  bottom: -10%;
}

.about__image__section .about__image__one img {
  border-radius: 10px;
}
.about__image__section .about__applicant {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 30px;
  z-index: 1;
}
@media screen and (max-width: 576px) {
  .about__image__section .about__applicant {
    position: relative;
    left: 0;
    transform: translate(0);
    bottom: 0;
    margin-bottom: 40px;
  }
}
.about__image__section .about__image__two {
  position: relative;
  bottom: 50px;
}
.about__image__section .about__image__two img {
  border-radius: 160px;
}
@media screen and (max-width: 576px) {
  .about__image__section .about__image__two img {
    border-radius: 10px;
  }
}

.rts__listing.style__six .single__listing .icon {
  background-color: var(--rts-button-3);
}

.apply__area__bg {
  background-color: #F5F3EA;
  border-radius: 16px;
  padding: 95px 60px;
}
@media screen and (max-width: 768px) {
  .apply__area__bg {
    padding: 50px;
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .apply__area__bg {
    padding: 40px 30px;
  }
}

.apply__area__img {
  position: absolute;
  bottom: -100px;
  left: 0;
}
@media screen and (max-width: 1200px) {
  .apply__area__img {
    left: -80px;
  }
}
.apply__area__img img {
  max-height: 305px;
}
@media screen and (max-width: 992px) {
  .apply__area__img img {
    max-height: 340px;
  }
}
@media screen and (max-width: 992px) {
  .apply__area__img {
    position: relative;
  }
}

.shape__image__six {
  position: absolute;
  left: 35%;
  top: 0;
}
@media screen and (max-width: 992px) {
  .shape__image__six {
    top: 25%;
    left: 30%;
  }
}

.max-320 {
  max-width: 320px;
}
@media screen and (max-width: 576px) {
  .max-320 {
    max-width: 450px;
  }
}

.max-410 {
  max-width: 410px;
}

@media screen and (max-width: 576px) {
  .footer__wrapper {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 490px) {
  .footer__wrapper {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.footer__home__one {
  background-image: url("../img/footer/footer__bg.png");
}

@media screen and (max-width: 768px) {
  .rts__footer__widget.max-320 {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .rts__footer__widget.max-360 {
    max-width: 100%;
  }
}
.rts__footer__widget ul li {
  margin-bottom: 10px;
}
.rts__footer__widget ul li:last-child {
  margin-bottom: 0;
}
.rts__footer__widget ul li a {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--rts--para);
  font-size: 16px;
  font-weight: 500;
  transition: var(--transition);
}
.rts__footer__widget ul li a i {
  color: var(--rts-primary);
}
.rts__footer__widget ul li a:hover {
  color: var(--rts-primary);
}

.rts__copyright {
  border-top: 1px solid rgba(125, 128, 135, 0.1);
}

.footer__home__two {
  background-image: url("../img/home-2/footer.png");
  padding-top: 220px;
}
.footer__home__two .rts__footer__widget ul li a {
  color: var(--rts-gray);
}

.footer__home__three {
  background-image: url("../img/home-3/footer__bg.png");
}

.footer__newsletter__three {
  min-width: 450px;
  background-color: var(--rts-white);
  padding: 10px 10px 10px 20px;
  border-radius: 4px;
  position: relative;
}
@media screen and (max-width: 576px) {
  .footer__newsletter__three {
    max-width: 100%;
    min-width: 100%;
  }
}
.footer__newsletter__three input {
  border: 0;
  padding: 13px 15px;
}
.footer__newsletter__three .newsletter__btn {
  padding: 13px;
  border-radius: 4px;
  background-color: var(--rts-heading);
  color: var(--rts-white);
  border: 0;
  font-size: 16px;
}
@media screen and (max-width: 576px) {
  .footer__newsletter__three .newsletter__btn {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.footer__bottom {
  border-top: 1px solid rgba(241, 241, 241, 0.1);
}

.footer__home__four {
  padding-top: 120px;
  position: relative;
}
.footer__home__four::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  background-image: url("../img/hom-4/footer__bg.svg");
}
@media screen and (max-width: 768px) {
  .footer__home__four .rts__footer__widget.max-410 {
    min-width: 100%;
  }
}

.newsletter.style-border {
  max-width: 385px;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .newsletter.style-border {
    max-width: 100%;
  }
}
.newsletter.style-border input {
  border-radius: 4px;
}

@media screen and (max-width: 576px) {
  .newsletter.mnw-500 {
    min-width: 100%;
  }
}

.style-border {
  border: 1px solid #DDD8F9;
}

.app__icon img {
  max-width: 165px;
  height: 54px;
}

.footer__five__copy {
  background-color: var(--rts-primary);
}

.footer__home__six {
  background-color: #F5F3EA;
}

.footer__six__copy p {
  color: var(--rts-para);
}

@media screen and (max-width: 768px) {
  .footer__social__left h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 576px) {
  .footer__social__left h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

.breadcrumb__background {
  background-image: url("../img/breadcrumb/breadcrumb.png");
  min-height: 450px;
}
@media screen and (max-width: 992px) {
  .breadcrumb__background {
    min-height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .breadcrumb-title {
    text-align: center;
  }
}

.breadcrumb__padding {
  padding: 210px 0 130px;
}
@media screen and (max-width: 992px) {
  .breadcrumb__padding {
    padding: 150px 0 130px;
  }
}
@media screen and (max-width: 768px) {
  .breadcrumb__padding {
    padding: 150px 0 80px;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  font-family: "rt-icons";
  font-size: 14px;
  padding-right: 10px;
  position: relative;
  top: 3px;
  color: var(--rts-button-1);
}

.breadcrumb li a {
  color: var(--rts-para);
}
.breadcrumb li.active {
  color: var(--rts-button-1);
}

.breadcrumb__area__shape {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 10%;
  top: 60%;
  transform: translate(0, -50%);
}
@media screen and (max-width: 992px) {
  .breadcrumb__area__shape {
    right: 0;
  }
}
@media screen and (max-width: 576px) {
  .breadcrumb__area__shape {
    display: none !important;
  }
}
.breadcrumb__area__shape .shape__one {
  right: 25%;
  position: absolute;
}
@media screen and (max-width: 992px) {
  .breadcrumb__area__shape .shape__one {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb__area {
    margin: 0 auto;
  }
}
.breadcrumb__area .rts__job__card__big .job__tags a, .breadcrumb__area .rts__author__card__big .job__tags a, .breadcrumb__area .rts__author__card .job__tags a {
  background-color: var(--rts-white);
}
.breadcrumb__area .breadcrumb__apply {
  position: absolute;
  right: 0;
  top: 52%;
}
@media screen and (max-width: 1200px) {
  .breadcrumb__area .breadcrumb__apply {
    margin-top: 30px;
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  .breadcrumb__area .breadcrumb__apply {
    margin: 0 auto;
    padding-top: 30px;
  }
}
.breadcrumb__area .apply__btn {
  color: var(--rts-white);
  font-weight: 500;
  padding: 20px 30px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .breadcrumb__area .apply__btn {
    padding: 15px 20px;
    font-size: 16px;
  }
}
.breadcrumb__area .apply__btn.no__fill__btn {
  color: var(--rts-heading);
  background-color: transparent;
}
.breadcrumb__area .apply__btn.no__fill__btn:hover {
  color: var(--rts-white);
  background-color: var(--rts-button-1);
  border-color: var(--rts-button-1);
}

.breadcrumb__area__shape.breadcrumb__style__four .shape__one {
  left: 10%;
}

.full__map iframe {
  width: 100%;
}

.navigation__menu ul {
  display: flex;
  max-width: max-content;
  position: relative;
  padding: 0;
  margin: 0;
}
.navigation__menu--item {
  position: relative;
  margin: 0 20px;
}
@media screen and (max-width: 1400px) {
  .navigation__menu--item {
    margin: 0 25px;
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .navigation__menu--item {
    margin: 0 15px;
  }
}
@media screen and (max-width: 1200px) {
  .navigation__menu--item {
    margin: 0 14px;
  }
}
.navigation__menu--item:last-child {
  margin-right: 0;
}
.navigation__menu--item:last-child::after {
  display: none;
}
.navigation__menu--item:first-child {
  margin-left: 0;
}
.navigation__menu--item:hover > a {
  color: var(--rts-heading) !important;
}
.navigation__menu--item::after {
  height: 2px;
  width: 2px;
  border-radius: 50px;
  background: var(--gray-1);
  top: 50%;
  right: -31px;
}
.navigation__menu--item__link {
  position: relative;
  font-size: 16px;
  text-transform: capitalize;
  padding: 35px 0;
  font-weight: 500;
  display: block;
  color: var(--rts-heading);
  transition: padding 0.3s ease;
}
.navigation__menu--item.has-child:hover ul.submenu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.navigation__menu--item.has-child:hover ul.submenu li.has-child:hover ul {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.navigation__menu--item.has-arrow {
  position: relative;
}
.navigation__menu--item.has-arrow::before {
  position: absolute;
  content: "";
  font-family: var(--fontawesome-pro);
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  color: var(--rts-heading);
  font-size: 12px;
  transition: var(--transition);
}
.navigation__menu--item.has-arrow:hover::before {
  content: "";
  font-family: var(--fontawesome-pro);
  transition: var(--transition);
  color: var(--rts-button-1);
}
.navigation__menu--item ul.submenu,
.navigation__menu--item .sub__style {
  position: absolute;
  top: 100%;
  min-width: 230px;
  background: var(--rts-white);
  z-index: 1024;
  display: inline-block;
  opacity: 0;
  padding: 20px 0 30px 0;
  transform: scaleY(0);
  transform-origin: top center;
  visibility: hidden;
  transition: var(--transition);
  left: 0;
  box-shadow: 0 36px 35px rgba(0, 0, 0, 0.08);
}
.navigation__menu--item ul.submenu li,
.navigation__menu--item .sub__style li {
  position: relative;
  display: block;
  padding: 0 30px;
}
.navigation__menu--item ul.submenu li:last-child,
.navigation__menu--item .sub__style li:last-child {
  margin-bottom: 0;
}
.navigation__menu--item ul.submenu li:hover > a,
.navigation__menu--item .sub__style li:hover > a {
  color: var(--rts-heading);
}
.navigation__menu--item ul.submenu li:hover > a::before,
.navigation__menu--item .sub__style li:hover > a::before {
  width: 80%;
}
.navigation__menu--item ul.submenu li:not(:last-child),
.navigation__menu--item .sub__style li:not(:last-child) {
  margin: 0 0 10px;
}
.navigation__menu--item ul.submenu li.has-arrow,
.navigation__menu--item .sub__style li.has-arrow {
  position: relative;
}
.navigation__menu--item ul.submenu li.has-arrow::before,
.navigation__menu--item .sub__style li.has-arrow::before {
  position: absolute;
  content: "";
  font-family: var(--fontawesome-pro);
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  color: var(--rts-heading);
  font-size: 14px;
  transition: var(--transition);
}
.navigation__menu--item ul.submenu li.has-arrow:hover::before,
.navigation__menu--item .sub__style li.has-arrow:hover::before {
  content: "";
  font-family: var(--fontawesome-pro);
  transition: var(--transition);
}
.navigation__menu--item ul.submenu li a,
.navigation__menu--item .sub__style li a {
  padding: 0;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  color: var(--rts-heading);
}
.navigation__menu--item ul.submenu li a::before,
.navigation__menu--item .sub__style li a::before {
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: var(--rts-heading);
  transition: var(--transition);
}
.navigation__menu--item ul.submenu li a:hover::before,
.navigation__menu--item .sub__style li a:hover::before {
  width: 100%;
}
.navigation__menu--item ul.submenu li ul,
.navigation__menu--item .sub__style li ul {
  top: 0;
  position: absolute;
  left: 100%;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top center;
  opacity: 0;
  transition: var(--transition);
}

.header__sticky {
  position: relative;
  top: 0;
  display: block;
  width: 100%;
  box-shadow: none;
  background: transparent;
  z-index: 999;
  margin: auto;
  align-items: center;
  left: 0;
  border-radius: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
  transform: translateY(0);
}
.header__sticky.fixed {
  position: fixed !important;
  box-shadow: 0px 7px 18px #1810100d;
  background: var(--rts-white);
}

.rts__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  cursor: pointer;
  text-align: center;
}
.rts__btn:active {
  color: inherit;
}
.rts__btn:focus {
  outline: none;
}
.rts__btn:disabled {
  cursor: not-allowed;
}

.fill__btn {
  background-color: var(--rts-button-1);
  border: 1px solid var(--rts-button-1);
  transition: var(--transition);
  color: var(--rts-white);
}
.fill__btn:hover {
  background-color: var(--rts-button-3);
  border-color: var(--rts-button-3);
}
.fill__btn.he-2 {
  background-color: var(--rts-button-2);
  border-color: var(--rts-button-2);
}
.fill__btn.he-2:hover {
  background-color: var(--rts-button-3);
  border-color: var(--rts-button-3);
}
.fill__btn.he-3 {
  background-color: var(--rts-button-3);
  border-color: var(--rts-button-3);
}
.fill__btn.he-3:hover {
  background-color: var(--rts-button-1);
  border-color: var(--rts-button-1);
}
.fill__btn.be-1 {
  background-color: var(--rts-button-1);
  border-color: var(--rts-button-1);
}
.fill__btn.be-1:hover {
  background-color: var(--rts-button-3);
  border-color: var(--rts-button-3);
}

.no__fill__btn {
  background-color: transparent;
  border: 1px solid var(--rts-button-1);
  transition: var(--transition);
  color: var(--rts-button-1);
}
.no__fill__btn:hover {
  background-color: var(--rts-button-3);
  border-color: var(--rts-button-3);
  color: var(--rts-white);
}
.no__fill__btn.he-2 {
  border-color: var(--rts-button-2);
  color: var(--rts-button-3);
}
.no__fill__btn.he-2:hover {
  background-color: var(--rts-button-2);
  border-color: var(--rts-button-2);
  color: var(--rts-white);
}
.no__fill__btn.he-3 {
  border-color: var(--rts-button-3);
  color: var(--rts-button-3);
}
.no__fill__btn.he-3:hover {
  background-color: var(--rts-button-3);
  border-color: var(--rts-button-3);
  color: var(--rts-white);
}
.no__fill__btn.active {
  background-color: var(--rts-button-1);
  color: var(--rts-white) !important;
  border-color: var(--rts-button-1);
}

.small__btn {
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: 10px;
}
.small__btn i {
  font-size: 15px;
}

.pricing__btn {
  padding: 17px 35px;
  color: var(--rts-heading);
  font-weight: 600;
  font-size: 16px;
  border: 1px solid var(--rts-button-1);
  background-color: var(--rts-white);
  border-radius: 10px;
  transition: var(--transition);
}
.pricing__btn:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  border-color: var(--rts-button-1);
}

.common__btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding: 15px 16px;
}

.border-4 {
  border-radius: 4px;
}

.border-6 {
  border-radius: 6px;
}

.border-10 {
  border-radius: 10px;
}

.rts__section__content .rts__section__title {
  letter-spacing: -2px;
}
@media screen and (max-width: 768px) {
  .rts__section__content .rts__section__title {
    letter-spacing: 0;
  }
}

.rts__workprocess__box {
  background: linear-gradient(226.58deg, #FEF2EB 1.52%, #F1F1F1 47.05%, #E4E0EA 99.84%);
  padding: 30px 42px;
  text-align: center;
}
.rts__workprocess__box .rts__icon {
  height: 76px;
  width: 76px;
  background-color: var(--rts-white);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: grid;
  place-items: center;
  margin: 0 auto 20px;
}
.rts__workprocess__box .rts__icon img {
  height: 40px;
  width: 40px;
}
.rts__workprocess__box .process__title {
  margin-bottom: 15px;
}
.rts__workprocess__box .work__readmore a {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: var(--rts-heading);
  transition: var(--transition);
}
.rts__workprocess__box .work__readmore a:hover {
  color: var(--rts-button-1);
}
.rts__workprocess__box .work__readmore a i {
  color: var(--rts-button-1);
}
.rts__workprocess__box.is__contact {
  background: transparent;
  border: 1px solid rgba(125, 128, 135, 0.3);
  padding: 40px 36px;
  transition: var(--transition);
}
.rts__workprocess__box.is__contact .rts__icon {
  background-color: #F1F1F1;
  box-shadow: none;
  transition: var(--transition);
}
.rts__workprocess__box.is__contact:hover {
  background: linear-gradient(226.58deg, #FEF2EB 1.52%, #F1F1F1 47.05%, #E4E0EA 99.84%);
  border-color: transparent;
}
.rts__workprocess__box.is__contact:hover .rts__icon {
  background-color: var(--rts-white);
}

.single__feature__list {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.single__feature__list .single__item .icon {
  height: 76px;
  width: 76px;
  background-color: var(--rts-gray);
  display: grid;
  place-items: center;
  border-radius: 6px;
}
.single__feature__list .single__item .content .h6 {
  font-weight: 600;
}

.rts__workprocess__image {
  position: relative;
}
.rts__workprocess__image img {
  max-height: 700px;
  border-top-left-radius: 320px;
  border-top-right-radius: 320px;
}
.rts__workprocess__image .rts__applicants {
  position: absolute;
  bottom: 50px;
  left: -150px;
}
@media screen and (max-width: 1200px) {
  .rts__workprocess__image .rts__applicants {
    left: -100px;
  }
}
@media screen and (max-width: 992px) {
  .rts__workprocess__image .rts__applicants {
    left: 0;
  }
}

.rts__applicants {
  max-width: max-content;
  border-radius: 10px;
  background-color: var(--rts-white);
  padding: 20px 30px;
  box-shadow: 0px 4px 40px rgba(61, 61, 61, 0.16);
}
.rts__applicants .font-20 {
  color: var(--rts-heading);
}

.applicant__list {
  display: flex;
  margin-right: 15px;
  flex-wrap: wrap;
}
.applicant__list .single__list {
  margin-right: -15px;
  position: relative;
}
.applicant__list .single__list .icon-plus {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: rgba(85, 60, 223, 0.6);
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: var(--rts-white);
  font-size: 20px;
  border: 5px solid #F9F8FF;
}
.applicant__list .single__list img {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 5px solid #F9F8FF;
}

/* ==== Cat Slider ==== */
.cat__slider__bg {
  position: relative;
  background-color: var(--rts-gray);
  background-image: url("../img/home-1/cat/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.single__cat, .cat__style__two .single__cat {
  padding: 22px 30px;
  border-radius: 10px;
  background-color: var(--rts-white);
  max-width: max-content;
  min-width: 305px;
  transition: var(--transition);
}
@media screen and (max-width: 992px) {
  .single__cat, .cat__style__two .single__cat {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }
}
.single__cat:hover {
  box-shadow: 0px 4px 40px rgba(73, 73, 73, 0.1);
}
.single__cat__icon {
  height: 60px;
  width: 60px;
  display: grid;
  place-items: center;
  border-radius: 6px;
  background-color: var(--rts-gray);
}
.single__cat__link {
  gap: 6px;
}
.single__cat__link a {
  font-size: 24px;
  color: var(--rts-heading);
  font-weight: 600;
  transition: var(--transition);
}
.single__cat__link a:hover {
  color: var(--rts-button-1);
}
@media screen and (max-width: 768px) {
  .single__cat__link a {
    font-size: 20px;
  }
}
.single__cat__link span {
  margin-top: 5px;
  font-weight: 500;
  line-height: 1;
}

.cat__style__two .single__cat {
  border: 1px solid rgba(125, 128, 135, 0.3);
  background-color: transparent;
  transition: var(--transition);
  margin: 2px 0;
}
.cat__style__two .single__cat:hover {
  background: var(--rts-white);
  box-shadow: 0px 4px 40px rgba(73, 73, 73, 0.1);
  border-color: var(--rts-white);
}

.single__cat.style__five {
  align-items: center;
  transition: var(--transition);
  padding: 15px 20px;
  background-color: rgba(241, 241, 241, 0.3);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1400px) {
  .single__cat.style__five {
    min-width: 100%;
  }
}
.single__cat.style__five .single__cat__icon {
  height: 70px;
  width: 70px;
}
.single__cat.style__five:hover {
  background-color: var(--rts-button-1);
}
.single__cat.style__five:hover .single__cat__link a {
  color: var(--rts-white);
}
.single__cat.style__five:hover span {
  color: var(--rts-white);
}

.single__cat.style__six {
  padding: 15px 20px;
  max-width: 100%;
  background: var(--rts-white);
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
  transition: var(--transition);
}
.single__cat.style__six .single__cat__icon {
  height: 70px;
  min-width: 70px;
  display: grid;
  place-items: center;
  background-color: var(--rts-gray);
  border-radius: 10px;
}
.single__cat.style__six:hover {
  background: #0B0D28;
}
.single__cat.style__six:hover .single__cat__link a {
  color: var(--rts-white);
}
.single__cat.style__six:hover span {
  color: var(--rts-white);
}

.single__cat.style__two {
  padding-top: 30px;
  padding-bottom: 35px;
  background: var(--rts-gray);
  transition: var(--transition);
}
.single__cat.style__two:hover {
  background: var(--rts-button-2);
}
.single__cat.style__two:hover .single__cat__link a, .single__cat.style__two:hover .single__cat__link span {
  color: var(--rts-white);
}

.style__two__control .slider__btn:hover {
  background-color: var(--rts-button-2);
}

@media screen and (max-width: 1200px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}
@media screen and (max-width: 768px) {
  .position-lg-absolute {
    position: static !important;
  }
}

@media screen and (max-width: 992px) {
  .position-md-absolute {
    position: absolute !important;
  }
}
@media screen and (max-width: 768px) {
  .position-md-absolute {
    position: static !important;
  }
}

@media screen and (max-width: 1200px) {
  .right-md-0 {
    right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .bottom-md-0 {
    bottom: 0;
  }
}

.rts__job__card {
  padding: 30px;
  border: 1px solid #dcdddf;
  border-radius: 10px;
  position: relative;
  transition: var(--transition);
}
.rts__job__card::before {
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  border: 0;
  transition: var(--transition);
}
.rts__job__card:hover {
  border-color: transparent;
}
.rts__job__card:hover .company__icon {
  background-color: var(--rts-white);
}
.rts__job__card:hover .featured__option span {
  background-color: var(--rts-white);
}
.rts__job__card:hover::before {
  opacity: 1;
}
.rts__job__card .company__icon {
  height: 60px;
  width: 60px;
  display: grid;
  place-items: center;
  border-radius: 6px;
  background-color: var(--rts-gray);
  transition: var(--transition);
}
.rts__job__card .featured__option span {
  background-color: var(--rts-gray);
  border-radius: 6px;
  padding: 10px;
  line-height: 1;
  font-size: 16px;
  display: block;
  transition: var(--transition);
  color: var(--rts-button-1);
}
.rts__job__card .job__title a {
  color: var(--rts-heading);
  font-weight: 600;
  letter-spacing: -0.5px;
}
.rts__job__card .job__tags a {
  padding: 10px;
  border-radius: 6px;
  background-color: var(--rts-gray);
  color: var(--rts-para);
  line-height: 1;
  text-transform: capitalize;
  transition: var(--transition);
  font-size: 16px;
  font-weight: 500;
}
.rts__job__card .job__tags a:hover {
  color: var(--rts-white);
  background-color: var(--rts-button-1);
}

.rts__job__card__big, .rts__author__card__big, .rts__author__card {
  background-color: var(--rts-white);
  border-radius: 10px;
  padding: 30px;
}
@media screen and (max-width: 576px) {
  .rts__job__card__big, .rts__author__card__big, .rts__author__card {
    font-size: 16px;
    font-weight: 400 !important;
  }
}
.rts__job__card__big.is__video__in, .is__video__in.rts__author__card__big, .is__video__in.rts__author__card {
  margin-top: -70px;
  margin-left: 50px;
  padding-right: 60px !important;
}
@media screen and (max-width: 992px) {
  .rts__job__card__big.is__video__in, .is__video__in.rts__author__card__big, .is__video__in.rts__author__card {
    margin-top: 50px;
    margin-left: 0;
    padding-right: 0 !important;
  }
}
.rts__job__card__big .company__icon, .rts__author__card__big .company__icon, .rts__author__card .company__icon {
  height: 120px;
  min-width: 120px;
  border-radius: 50%;
  background-color: var(--rts-gray);
  display: flex;
  justify-content: center;
  transition: var(--transition);
  align-items: center;
}
@media screen and (max-width: 992px) {
  .rts__job__card__big .company__icon, .rts__author__card__big .company__icon, .rts__author__card .company__icon {
    display: none;
  }
}
.rts__job__card__big .company__icon img, .rts__author__card__big .company__icon img, .rts__author__card .company__icon img {
  height: 65px;
  width: 65px;
}
.rts__job__card__big .job__meta, .rts__author__card__big .job__meta, .rts__author__card .job__meta {
  color: var(--rts-para);
}
.rts__job__card__big .job__meta .job__title, .rts__author__card__big .job__meta .job__title, .rts__author__card .job__meta .job__title {
  color: var(--rts-heading);
}
.rts__job__card__big .job__meta i, .rts__author__card__big .job__meta i, .rts__author__card .job__meta i {
  color: var(--rts-heading);
}
.rts__job__card__big .job__meta span.badge, .rts__author__card__big .job__meta span.badge, .rts__author__card .job__meta span.badge {
  color: var(--rts-button-1);
  padding: 10px;
  font-weight: 500;
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
  border-radius: 6px;
}
.rts__job__card__big .job__tags a, .rts__author__card__big .job__tags a, .rts__author__card .job__tags a {
  padding: 5px 10px;
  border-radius: 6px;
  background-color: transparent;
  color: var(--rts-para);
  line-height: 1.2;
  text-transform: capitalize;
  transition: var(--transition);
  border: 1px solid #F1F1F1;
  font-weight: 500;
}
.rts__job__card__big .job__tags a:hover, .rts__author__card__big .job__tags a:hover, .rts__author__card .job__tags a:hover {
  color: var(--rts-white);
  background-color: var(--rts-button-3);
}
.rts__job__card__big .job__sallery, .rts__author__card__big .job__sallery, .rts__author__card .job__sallery {
  color: var(--rts-para);
  font-weight: 500;
}
.rts__job__card__big .job__sallery span, .rts__author__card__big .job__sallery span, .rts__author__card .job__sallery span {
  font-size: 12px;
}
.rts__job__card__big .job__sallery i, .rts__author__card__big .job__sallery i, .rts__author__card .job__sallery i {
  color: var(--rts-heading);
}

.apply__btn {
  padding: 20px 18px;
  border-radius: 6px;
  background-color: #F1F1F1;
  color: var(--rts-heading);
  transition: var(--transition);
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .apply__btn {
    padding: 12px 15px;
    border-radius: 6px;
    line-height: 1.2;
  }
}
.apply__btn:hover {
  background-color: var(--rts-button-3);
  color: var(--rts-white);
}
.apply__btn.is__home {
  padding: 14px 20px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .apply__btn.is__home {
    padding: 12px 14px;
    border-radius: 6px;
  }
}
@media screen and (max-width: 768px) {
  .apply__btn.is__home {
    padding: 12px 14px;
    border-radius: 6px;
  }
}

.rts__single__employer {
  padding: 20px 30px;
  background-color: transparent;
  border: 1px solid rgba(125, 128, 135, 0.3);
  border-radius: 10px;
  transition: var(--transition);
}
.rts__single__employer .icon {
  height: 60px;
  width: 60px;
  display: grid;
  place-items: center;
  border-radius: 6px;
  background-color: var(--rts-gray);
  transition: var(--transition);
}
.rts__single__employer:hover {
  box-shadow: 0px 4px 40px rgba(73, 73, 73, 0.1);
  background-color: var(--rts-white);
  border-color: var(--rts-white);
}

.rts__job__card.style__four {
  position: relative;
  text-align: center;
  border-radius: 0;
}
.rts__job__card.style__four:hover {
  background-color: var(--rts-gray);
}
.rts__job__card.style__four:hover .fill__btn {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}
.rts__job__card.style__four .fill__btn {
  padding: 16px 20px;
  border-radius: 0;
  background-color: var(--rts-gray);
  border: 0;
  color: var(--rts-heading);
  font-weight: 500;
  margin-top: 25px;
  text-transform: capitalize;
}

.rts__job__card.style__five:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}
.rts__job__card.style__five:hover .job__title {
  color: var(--rts-white);
}

.rts__job__card.style__six {
  background-color: var(--rts-white);
  border: none;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
}
.rts__job__card.style__six:hover {
  background-color: var(--rts-button-3);
  color: var(--rts-white);
}
.rts__job__card.style__six:hover .job__title {
  color: var(--rts-white);
}

.rts__single__employer.style__six {
  background-color: var(--rts-white);
  border: 0;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
  transition: var(--transition);
}
@media screen and (max-width: 576px) {
  .rts__single__employer.style__six {
    padding: 30px;
  }
}
.rts__single__employer.style__six:hover {
  background-color: var(--rts-button-3);
  color: var(--rts-white);
}
.rts__single__employer.style__six:hover .content .text-dark {
  color: var(--rts-white) !important;
}
.rts__single__employer.style__six:hover i.text-dark {
  color: var(--rts-white) !important;
}
.rts__single__employer.style__six:hover .apply__btn {
  background-color: var(--rts-white);
  color: var(--rts-button-3);
}

.rts__job__card__big.style__two, .style__two.rts__author__card__big, .style__two.rts__author__card {
  background-color: var(--rts-white);
  transition: var(--transition);
}
.rts__job__card__big.style__two i, .style__two.rts__author__card__big i, .style__two.rts__author__card i {
  color: var(--rts-button-2);
}
.rts__job__card__big.style__two .bg-none, .style__two.rts__author__card__big .bg-none, .style__two.rts__author__card .bg-none {
  background: none !important;
}
.rts__job__card__big.style__two .job__tags a, .style__two.rts__author__card__big .job__tags a, .style__two.rts__author__card .job__tags a {
  border-radius: 25px;
  padding: 7px 10px;
}
.rts__job__card__big.style__two:hover, .style__two.rts__author__card__big:hover, .style__two.rts__author__card:hover {
  background-color: var(--rts-button-2);
  color: var(--rts-white);
}
.rts__job__card__big.style__two:hover .job__title, .style__two.rts__author__card__big:hover .job__title, .style__two.rts__author__card:hover .job__title {
  color: var(--rts-white);
}
.rts__job__card__big.style__two:hover div, .style__two.rts__author__card__big:hover div, .style__two.rts__author__card:hover div {
  color: var(--rts-white);
}
.rts__job__card__big.style__two:hover div i, .style__two.rts__author__card__big:hover div i, .style__two.rts__author__card:hover div i {
  color: var(--rts-white);
}
.rts__job__card__big.style__two:hover .badge, .style__two.rts__author__card__big:hover .badge, .style__two.rts__author__card:hover .badge {
  color: var(--rts-white);
}
.rts__job__card__big.style__two:hover .job__tags a, .style__two.rts__author__card__big:hover .job__tags a, .style__two.rts__author__card:hover .job__tags a {
  background-color: #FFC442;
  color: var(--rts-white);
  border-color: #FFC442;
}

.job__board__bg {
  background-image: url(../img/home-2/section__bg2.png);
  padding-bottom: 190px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
@media screen and (max-width: 992px) {
  .job__board__bg {
    padding-bottom: 0;
  }
}

.rts__author__card {
  padding: 30px 22px;
  background-color: var(--rts-white);
}
.rts__author__card .author_icon {
  margin: 0 auto;
}
.rts__author__card .author_icon img {
  height: 60px;
  width: 60px;
}
.rts__author__card .apply__btn {
  padding: 10px 20px;
  margin: 0 auto;
  border-radius: 25px;
  background: transparent;
  border: 1px solid rgba(125, 128, 135, 0.3);
  line-height: 1.2;
}
.rts__author__card .job__tags a {
  background-color: var(--rts-gray);
}
.rts__author__card:hover .apply__btn {
  background-color: var(--rts-white);
  color: var(--rts-button-3);
}
.rts__author__card__big .apply__btn {
  padding: 15px 18px;
}

.featured__section__bg {
  background-image: url(../img/home-2/section__bg.png);
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.job__search__section {
  background-color: var(--rts-white);
  padding: 30px;
  background: linear-gradient(228.26deg, #FEF2EB 0%, #F1F1F1 46.25%, #E4E0EA 99.86%);
  border-radius: 10px;
}

.search__item {
  position: relative;
}
.search__item input {
  padding: 15px 20px;
  padding-left: 45px;
  border-radius: 4px;
  border: 0;
  position: relative;
  width: 100%;
}
.search__item label {
  font-weight: 500;
}
.search__item i {
  position: absolute;
  left: 20px;
  transform: translateY(-50%);
  color: var(--rts-heading);
  margin-top: 26px;
  font-size: 16px;
}
.search__item .nice-select {
  width: 100%;
  padding: 6px 20px;
  padding-left: 45px;
  height: auto;
  font-size: inherit;
  position: relative;
  border: 0;
}
.search__item .nice-select.open::before {
  content: "";
  font-family: var(--fontawesome-pro);
  transition: var(--transition);
}
.search__item .nice-select ul.list {
  width: 100%;
}
.search__item .nice-select ul.list li.selected {
  font-weight: 500;
}
.search__item .nice-select:focus {
  border-color: transparent;
}
.search__item .nice-select::before {
  position: absolute;
  content: "";
  font-family: var(--fontawesome-pro);
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: var(--rts-heading);
  font-size: 12px;
  transition: var(--transition);
}
.search__item .nice-select::after {
  border-bottom: 0;
  border-right: 0;
}
.search__item__list label {
  text-transform: capitalize;
  color: var(--rts-heading);
}
.search__item__list span {
  font-size: 16px;
}
.search__item__list .list {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(125, 128, 135, 0.3);
}
.search__item__list .list:not(:first-child) {
  padding-top: 10px;
}
.search__item__list .list:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.search__item.no-icon .nice-select {
  padding-left: 20px;
}
.search__item .job__tags a {
  padding: 10px 12px;
  border-radius: 2px;
  background: var(--rts-white);
  color: var(--rts-para);
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
}

.job__search__btn {
  padding: 10px;
  color: var(--rts-heading);
  border-radius: 3px;
  font-weight: 500;
}

.top__query .grid-style,
.top__query .list-style {
  padding: 10px 12px;
  border-radius: 6px;
  color: var(--rts-heading);
  gap: 8px;
}
.top__query .grid-style:hover,
.top__query .list-style:hover {
  color: var(--rts-white);
}
.top__query .category-select {
  min-width: 180px;
  flex-wrap: wrap;
}
.top__query .category-select .nice-select {
  border: 1px solid #D8D9DB;
}
.top__query .category-select .nice-select .current {
  color: var(--rts-heading);
}

.recruiting {
  padding: 30px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
}
.recruiting .recruiting__btn {
  width: 100%;
  padding: 20px 0;
  border-radius: 6px;
}

.category-select .min-200 {
  min-width: 200px;
}
.category-select .nice-select {
  background-color: var(--rts-gray);
  border: none !important;
  padding: 4px 50px 4px 20px;
}

.rts__job__card__big.style__gradient, .style__gradient.rts__author__card__big, .rts__job__card__big.style__gradient__two, .style__gradient__two.rts__author__card__big, .style__gradient.rts__author__card, .rts__author__card.style__gradient__two {
  background: var(--rts-white);
  position: relative;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
}
.rts__job__card__big.style__gradient .job__meta i, .style__gradient.rts__author__card__big .job__meta i, .rts__job__card__big.style__gradient__two .job__meta i, .style__gradient__two.rts__author__card__big .job__meta i, .style__gradient.rts__author__card .job__meta i, .rts__author__card.style__gradient__two .job__meta i {
  color: var(--rts-para);
}
.rts__job__card__big.style__gradient .job__tags a, .style__gradient.rts__author__card__big .job__tags a, .rts__job__card__big.style__gradient__two .job__tags a, .style__gradient__two.rts__author__card__big .job__tags a, .style__gradient.rts__author__card .job__tags a, .rts__author__card.style__gradient__two .job__tags a {
  border-radius: 25px;
  padding: 5px 10px;
}
.rts__job__card__big.style__gradient::before, .style__gradient.rts__author__card__big::before, .rts__job__card__big.style__gradient__two::before, .style__gradient__two.rts__author__card__big::before, .style__gradient.rts__author__card::before, .rts__author__card.style__gradient__two::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: var(--transition);
  z-index: -1;
  background: linear-gradient(228.26deg, #FEF2EB 0%, #F1F1F1 46.25%, #E4E0EA 99.86%);
  border-radius: 10px;
}
.rts__job__card__big.style__gradient:hover, .style__gradient.rts__author__card__big:hover, .rts__job__card__big.style__gradient__two:hover, .style__gradient__two.rts__author__card__big:hover, .style__gradient.rts__author__card:hover, .rts__author__card.style__gradient__two:hover {
  background: unset;
}
.rts__job__card__big.style__gradient:hover .company__icon, .style__gradient.rts__author__card__big:hover .company__icon, .rts__job__card__big.style__gradient__two:hover .company__icon, .style__gradient__two.rts__author__card__big:hover .company__icon, .style__gradient.rts__author__card:hover .company__icon, .rts__author__card.style__gradient__two:hover .company__icon {
  background-color: var(--rts-white);
}
.rts__job__card__big.style__gradient:hover .bookmark__btn, .style__gradient.rts__author__card__big:hover .bookmark__btn, .rts__job__card__big.style__gradient__two:hover .bookmark__btn, .style__gradient__two.rts__author__card__big:hover .bookmark__btn, .style__gradient.rts__author__card:hover .bookmark__btn, .rts__author__card.style__gradient__two:hover .bookmark__btn {
  background-color: var(--rts-white);
  color: var(--rts-heading);
}
.rts__job__card__big.style__gradient:hover .job__tags a, .style__gradient.rts__author__card__big:hover .job__tags a, .rts__job__card__big.style__gradient__two:hover .job__tags a, .style__gradient__two.rts__author__card__big:hover .job__tags a, .style__gradient.rts__author__card:hover .job__tags a, .rts__author__card.style__gradient__two:hover .job__tags a {
  border-color: var(--rts-white);
}
.rts__job__card__big.style__gradient:hover .job__tags a:hover, .style__gradient.rts__author__card__big:hover .job__tags a:hover, .rts__job__card__big.style__gradient__two:hover .job__tags a:hover, .style__gradient__two.rts__author__card__big:hover .job__tags a:hover, .style__gradient.rts__author__card:hover .job__tags a:hover, .rts__author__card.style__gradient__two:hover .job__tags a:hover {
  background-color: var(--rts-white);
  color: var(--rts-heading);
}
.rts__job__card__big.style__gradient:hover::before, .style__gradient.rts__author__card__big:hover::before, .rts__job__card__big.style__gradient__two:hover::before, .style__gradient__two.rts__author__card__big:hover::before, .style__gradient.rts__author__card:hover::before, .rts__author__card.style__gradient__two:hover::before {
  opacity: 1;
}
.rts__job__card__big.style__gradient__two .company__icon, .style__gradient__two.rts__author__card__big .company__icon, .style__gradient__two.rts__author__card .company__icon {
  align-self: flex-start;
}
.rts__job__card__big.style__gradient__two .job__meta i, .style__gradient__two.rts__author__card__big .job__meta i, .style__gradient__two.rts__author__card .job__meta i {
  color: var(--rts-heading);
}
.rts__job__card__big.style__gradient__two .job__tags a, .style__gradient__two.rts__author__card__big .job__tags a, .style__gradient__two.rts__author__card .job__tags a {
  border-radius: 6px;
}
.rts__job__card__big.style__gradient__two:hover .apply__btn, .style__gradient__two.rts__author__card__big:hover .apply__btn, .style__gradient__two.rts__author__card:hover .apply__btn {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}
.rts__job__card__big.style__gradient__two:hover .job__tags a, .style__gradient__two.rts__author__card__big:hover .job__tags a, .style__gradient__two.rts__author__card:hover .job__tags a {
  border-radius: 6px;
  background-color: var(--rts-white);
  color: var(--rts-heading);
}

.bookmark__btn {
  height: 58px;
  width: 58px;
  display: grid;
  place-items: center;
  border-radius: 50px;
  background-color: var(--rts-gray);
  color: var(--rts-heading);
  font-size: 20px;
  transition: var(--transition);
}
.bookmark__btn:hover {
  background-color: var(--rts-button-3);
  color: var(--rts-white);
}

.advance__search__form {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
}
@media screen and (max-width: 1200px) {
  .advance__search__form {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .advance__search__form {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .advance__search__form {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
}

.advance__search__bg {
  margin-top: -40px;
  background-color: var(--rts-white);
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 16px;
  padding: 15px;
}
@media screen and (max-width: 576px) {
  .advance__search__bg {
    padding: 30px;
  }
}
.advance__search__bg .search__item {
  width: 100%;
}
.advance__search__bg .search__item input,
.advance__search__bg .search__item .nice-select {
  background-color: var(--rts-gray);
  border: none;
  border-radius: 6px;
}
.advance__search__bg .search__item i {
  z-index: 9;
}
.advance__search__bg .job__search {
  padding: 16px 30px;
  width: 100%;
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  border-radius: 10px;
  border: none;
  max-width: max-content;
  font-weight: 500;
}

.rts__image__section {
  background-image: url(../img/home-1/we-are/image__bg.svg);
  border-radius: 10px;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 30px;
}

.rts__content__section {
  max-width: 600px;
}

.rts__listing {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media screen and (max-width: 576px) {
  .rts__listing {
    grid-template-columns: repeat(1, 1fr);
  }
}

.single__listing .icon {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: var(--rts-button-1);
  display: grid;
  place-items: center;
  font-size: 16px;
  color: var(--rts-white);
}

.rts__pricing__box {
  padding: 30px;
  border: 1px solid rgba(125, 128, 135, 0.3);
  border-radius: 10px;
  transition: var(--transition);
  position: relative;
  z-index: 1;
}
.rts__pricing__box::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: var(--transition);
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%);
}
.rts__pricing__box:hover {
  border-color: transparent;
}
.rts__pricing__box:hover::before {
  opacity: 1;
}
.rts__pricing__box:hover .pricing__btn {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}

.plan__feature {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.plan__feature li {
  display: flex;
  gap: 15px;
  align-items: center;
}
.plan__feature li i {
  color: var(--rts-primary);
  font-size: 14px;
}

.monthly__pricing, .yearly__pricing {
  display: none;
  transition: var(--transition);
}
.monthly__pricing.active, .yearly__pricing.active {
  display: block;
}

.pricing__style__three .plan__feature li i {
  color: var(--rts-primary);
  font-size: 20px;
}
.pricing__style__three .pricing__btn {
  border-color: #7D8087;
}
.pricing__style__three:hover {
  background-color: var(--rts-button-3);
  color: var(--rts-white);
}
.pricing__style__three:hover .pricing__btn {
  background-color: var(--rts-white);
  color: var(--rts-button-3);
  border-color: var(--rts-white);
}
.pricing__style__three:hover .plan__price span.h4 {
  color: var(--rts-white);
}
.pricing__style__three:hover .plan__feature li i {
  color: var(--rts-white);
}
.pricing__style__three:hover .text-dark {
  color: var(--rts-white) !important;
}

.rts__pricing__tab .nav-link {
  background-color: var(--rts-gray);
  padding: 15px 20px;
  border-radius: 6px;
  color: var(--rts-button-3);
}
.rts__pricing__tab .nav-link.active {
  background-color: var(--rts-button-3);
  color: var(--rts-white);
}

.rts__pricing__box.pricing__style__three::before {
  display: none;
}

.rts__single__blog {
  padding: 18px 18px 30px 18px;
  border-radius: 10px;
  border: 1px solid rgba(125, 128, 135, 0.3);
  position: relative;
}
.rts__single__blog::before {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%);
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: var(--transition);
}
.rts__single__blog:hover {
  border-color: transparent;
}
.rts__single__blog:hover::before {
  opacity: 1;
}
.rts__single__blog:hover .readmore__btn i {
  transform: rotate(0deg);
}
.rts__single__blog .blog__img img {
  border-radius: 10px;
  min-height: 240px;
  object-fit: cover;
  transition: var(--transition);
  transform: scale(1);
  overflow: hidden;
}

.blog__meta {
  padding: 12px 12px 0 12px;
}
.blog__meta a {
  transition: var(--transition);
}
.blog__meta a:hover {
  color: var(--rts-button-1);
}
.blog__meta__info span,
.blog__meta__info a {
  font-size: 16px;
  color: var(--rts-para);
}

.readmore__btn {
  color: var(--rts-heading);
  font-weight: 500;
}
.readmore__btn i {
  color: var(--rts-primary);
  transform: rotate(-35deg);
  transition: var(--transition);
}

.rts__single__blog.blog__style__four:hover::before {
  background: var(--rts-gray);
}

.rts__single__blog.style__six {
  background-color: var(--rts-white);
  border: 0;
  padding: 30px;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 6px;
}
.rts__single__blog.style__six .cat__link {
  font-size: 14px;
  background-color: var(--rts-gray);
  padding: 8px 15px;
  line-height: 1;
  color: var(--rts-heading);
  border-radius: 2px;
}

.blog__slider .blog__content a.category {
  background-color: var(--rts-gray);
  padding: 10px 15px;
  max-width: max-content;
  border-radius: 25px;
  color: var(--rts-para);
  margin-bottom: 20px;
  line-height: 1.2;
  transition: var(--transition);
}
.blog__slider .blog__content a.category:hover {
  background-color: var(--rts-button-2);
  color: var(--rts-white);
}
.blog__slider .blog__content__meta {
  max-width: 100%;
}
.blog__slider .blog__content .blog__thumb img {
  min-width: 615px;
}
@media screen and (max-width: 992px) {
  .blog__slider .blog__content .blog__thumb img {
    width: 100% !important;
    min-width: 100%;
  }
}

.latest__blog .thumb img {
  height: 100px;
  min-width: 100px;
}
.latest__blog .content a {
  font-size: 18px;
  color: var(--rts-heading);
}

@media screen and (max-width: 576px) {
  .rts__single__blog.blog__full__width {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 576px) {
  .rts__single__blog.blog__full__width .blog__img {
    width: 100%;
  }
}
.rts__single__blog.blog__full__width .blog__img img {
  max-width: 250px;
  height: 200px;
  object-fit: cover;
}
@media screen and (max-width: 576px) {
  .rts__single__blog.blog__full__width .blog__img img {
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}
.rts__single__blog.blog__full__width .blog__meta {
  padding: 0;
}

.rts__listing.is__3 {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 1200px) {
  .rts__listing.is__3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .rts__listing.is__3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.job__tags.is__blog__details a {
  background-color: var(--rts-gray);
  padding: 6px 12px;
  max-width: max-content;
  border-radius: 2px;
  color: var(--rts-para);
  line-height: 1;
  font-weight: 500;
  transition: var(--transition);
}
.job__tags.is__blog__details a:hover {
  color: var(--rts-white);
  background-color: var(--rts-button-1);
}

.comment__list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.comment__list .reply__btn {
  color: var(--rts-button-1);
  font-weight: 500;
}

.rts__blockquote {
  padding: 95px 68px 78px 38px;
  background: linear-gradient(229.19deg, #FEF2EB 0%, #F1F1F1 47.08%, #E4E0EA 101.65%);
  border-radius: 16px;
}
.rts__blockquote h6 {
  color: var(--rts-para);
  position: relative;
  max-width: max-content;
}
.rts__blockquote h6::before {
  content: "";
  position: absolute;
  background-image: url("../img/icon-pac/quote-1.svg");
  top: -40px;
  left: 0;
  height: 28px;
  width: 36px;
}
.rts__blockquote h6::after {
  content: "";
  position: absolute;
  background-image: url("../img/icon-pac/quote-1.svg");
  bottom: -20px;
  right: 0;
  height: 28px;
  width: 36px;
}

.rts__social a {
  display: inline-block;
  font-size: 20px;
  color: var(--rts-para);
  transition: var(--transition);
}
.rts__social a:hover {
  color: var(--rts-primary);
}

.social__two a {
  color: var(--rts-white);
}
.social__two a:hover {
  color: var(--rts-button-2);
}

.social__three a {
  color: var(--rts-white);
}

.single__destination__item {
  max-width: 305px;
  position: relative;
}
.single__destination__item.is__full__width {
  max-width: 100%;
}
.single__destination__item .destination__thumb {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.single__destination__item .destination__thumb::before {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(102, 102, 102, 0) 100%);
  border-radius: 10px;
  transition: var(--transition);
}
.single__destination__item .destination__thumb img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}
.single__destination__item .destination__meta {
  transition: var(--transition);
  position: absolute;
  width: 100%;
  bottom: 30px;
  color: var(--rts-white);
  left: 50%;
  transform: translate(-50%, 0);
}
.single__destination__item .destination__meta a {
  color: var(--rts-white);
}
.single__destination__item:hover .destination__meta {
  transform: translate(-50%, -200%);
}

.explore__slider .single__destination__item {
  max-width: 100%;
}
.explore__slider .swiper-slide.long__item {
  width: 50% !important;
}
.explore__slider .swiper-slide.long__item .single__destination__item {
  max-width: 100%;
}

.single__destination__item.style__two {
  max-width: 100%;
}

.offcanvas {
  padding: 30px;
}

.mean-container .mean-bar {
  background: transparent;
}
.mean-container .mean-bar a.meanmenu-reveal {
  display: none !important;
}
.mean-container .mean-bar .mean-nav {
  margin-top: 0;
  background: transparent;
}
.mean-container .mean-bar .mean-nav ul.list-unstyled {
  display: block !important;
}
.mean-container .mean-bar .mean-nav .navigation__menu--item {
  margin: 0;
}
.mean-container .mean-bar .mean-nav .navigation__menu--item::before {
  display: none;
}
.mean-container .mean-bar .mean-nav .navigation__menu--item ul.submenu, .mean-container .mean-bar .mean-nav .navigation__menu--item .sub__style {
  position: unset;
  top: inherit;
  min-width: inherit;
  background: unset;
  z-index: unset;
  display: inherit;
  opacity: unset;
  padding: inherit;
  transform: inherit;
  transform-origin: inherit;
  visibility: unset;
  transition: unset;
  left: inherit;
  box-shadow: unset;
  margin-left: 5px;
}
.mean-container .mean-bar .mean-nav .navigation__menu--item ul.submenu .has-arrow .mean-expand, .mean-container .mean-bar .mean-nav .navigation__menu--item .sub__style .has-arrow .mean-expand {
  display: block;
  margin: 0 auto;
}
.mean-container .mean-bar .mean-nav .navigation__menu--item ul.submenu li a::before, .mean-container .mean-bar .mean-nav .navigation__menu--item .sub__style li a::before,
.mean-container .mean-bar .mean-nav .navigation__menu--item .sub__style li.has-arrow::before {
  position: unset;
  content: unset;
}
.mean-container .mean-bar .mean-nav ul ul li {
  border-top: 1px solid rgba(125, 128, 135, 0.3);
  width: 90%;
  margin: 0;
  padding: 0 15px;
}
.mean-container .mean-bar .mean-nav ul ul li a {
  padding: 0;
  margin: 5px 0;
}

.mean-container .mean-nav ul li a {
  border-color: rgba(125, 128, 135, 0.3);
  padding: 10px 15px;
  text-transform: capitalize;
  color: var(--rts-heading);
}
.mean-container .mean-nav ul li a.mean-expand {
  width: 30px;
  height: auto;
  padding: 5px 15px !important;
  border: unset !important;
  background: unset !important;
  font-weight: normal !important;
}

.style__one .accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--rts-gray);
  box-shadow: none;
  border-color: var(--rts-gray) !important;
}
.style__one .accordion-button:not(.collapsed)::after {
  content: "";
  font-family: var(--fontawesome-pro);
  background-image: none;
  transform: none;
  font-size: 16px;
}
.style__one .accordion-button::after {
  content: "+";
  font-family: var(--fontawesome-pro);
  background-image: none;
  font-size: 16px;
}

.rts__pagination li a {
  height: 48px;
  width: 48px;
  border-radius: 6px;
  border: 1px solid rgba(125, 128, 135, 0.3);
  display: grid;
  place-items: center;
  color: var(--rts-para);
  position: relative;
  z-index: 1;
  transition: var(--transition);
}
.rts__pagination li a:hover {
  border-color: var(--rts-button-1);
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}
.rts__pagination li a:hover::before {
  opacity: 1;
}
.rts__pagination li a.inactive {
  border: none;
}
.rts__pagination li a.inactive::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  background: linear-gradient(228.49deg, #FEF2EB 0%, #F1F1F1 46.42%, #E4E0EA 100.23%);
  border-radius: 6px;
}
.rts__pagination li a.inactive:hover {
  color: var(--rts-heading);
  cursor: not-allowed;
}
.rts__pagination.rounded__pagination a {
  border-radius: 50%;
}
.rts__pagination.rounded__pagination a.inactive {
  border-radius: 50%;
}
.rts__pagination.rounded__pagination a.inactive::before {
  border-radius: 50%;
}

.cta__h5__bg {
  background-color: var(--rts-button-1);
}

.rts__cta__content {
  padding: 75px 0;
}
.rts__cta__content .cta__content {
  max-width: 720px;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .rts__cta__content .cta__content {
    padding: 0 30px;
  }
}
.rts__cta__content .cta__shape {
  left: 50%;
  top: 50%;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .rts__cta__content .cta__shape {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .rts__cta__content .cta__shape img {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .rts__cta__content .cta__shape img {
    width: 70%;
  }
}
.rts__cta__content .newsletter {
  max-width: 490px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .rts__cta__content .newsletter {
    max-width: 100%;
  }
}
.rts__cta__content .newsletter input[type=email] {
  padding: 20px 45px;
}

.swiper-slide {
  transform: none;
}

.timeline {
  position: relative;
}
.timeline li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 30px;
}
.timeline li:before {
  position: absolute;
  left: 0;
  top: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--rts-button-1);
  content: "";
}
.timeline li::after {
  position: absolute;
  left: 4.5px;
  top: 15px;
  height: 110%;
  width: 1px;
  z-index: -1;
  background-color: rgba(125, 128, 135, 0.3);
  content: "";
}
.timeline li:last-child::after {
  display: none;
}
.timeline li .timeline__title {
  color: var(--rts-button-1);
  font-weight: 500;
  margin-bottom: 5px;
}
.timeline li .timeline__subtitle {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.rts__slider__control .slider__btn {
  height: 43px;
  width: 40px;
  background-color: var(--rts-white);
  border-radius: 6px;
  display: grid;
  place-items: center;
  font-size: 14px;
  transition: var(--transition);
}
.rts__slider__control .slider__btn:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}

.rts__slider__control .rts__pagination {
  max-width: max-content;
  margin: 0 auto;
}
.rts__slider__control .rts__pagination .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--rts-para);
  transition: var(--transition);
}
.rts__slider__control .rts__pagination .swiper-pagination-bullet-active {
  background-color: var(--rts-button-3);
  width: 45px;
  border-radius: 5px;
}
.rts__slider__control.style__two .rts__pagination .swiper-pagination-bullet {
  background-color: var(--rts-para);
}
.rts__slider__control.style__two .rts__pagination .swiper-pagination-bullet-active {
  background-color: var(--rts-button-2);
}

.style-gray .slider__btn {
  background-color: var(--rts-gray);
}

.style__two__control .slider__btn:hover {
  background-color: var(--rts-button-2);
}

.similar__form {
  max-width: 600px;
  min-width: 600px;
}
@media screen and (max-width: 576px) {
  .similar__form {
    min-width: 100%;
    max-width: 100%;
  }
}
.similar__form input:not([type=checkbox]) {
  border: 1px solid var(--rts-border);
  padding: 15px 20px 15px 45px;
  border-radius: 4px;
  width: 100%;
}
.similar__form i.icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.form__padding {
  padding: 40px 45px;
}

.has__line {
  position: relative;
}
.has__line::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--rts-border);
  bottom: 12px;
  left: 0;
  z-index: 1;
}
.has__line p {
  max-width: max-content;
  margin: 0 auto;
  padding: 0 12px;
  font-weight: 500;
  position: relative;
  z-index: 2;
  background-color: var(--rts-white);
}

.tab__switch {
  display: flex;
  gap: 30px;
}
.tab__switch .rts__btn {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px 30px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--rts-gray);
}
.tab__switch .rts__btn.active {
  background-color: var(--rts-primary);
  color: var(--rts-white);
}

.form-group .rts__btn {
  padding: 18px 0;
  border-radius: 4px;
  font-weight: 500;
}

.is__social button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 15px 20px;
  border-radius: 4px;
  background-color: var(--rts-gray);
  width: 100%;
  font-size: 16px;
  color: var(--rts-para);
  transition: var(--transition);
}
.is__social button:hover {
  background-color: var(--rts-primary);
  color: var(--rts-white);
}

.similar__modal .modal-dialog {
  min-width: 600px;
  border-radius: 16px;
  border: 1px solid var(--rts-border);
}
@media screen and (max-width: 576px) {
  .similar__modal .modal-dialog {
    min-width: auto;
  }
}

.is__half__section {
  margin-top: -150px;
}
@media screen and (max-width: 768px) {
  .is__half__section {
    margin-top: 0;
    padding-top: 100px;
  }
}

.is__no__border .rts__workprocess__box {
  border: none;
}

.shadow-rt-sm {
  box-shadow: 0px 4px 80px rgba(28, 28, 28, 0.1);
}

.rts__back__top {
  background: var(--rts-button-1);
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  border-radius: 25px;
  display: none;
  text-align: center;
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  transition: var(--transition);
}

.rts__back__top.show {
  display: block;
  bottom: 30px;
  opacity: 1;
}

.home__two .rts__back__top {
  background: var(--rts-button-2);
}

.home__three .rts__back__top {
  background: var(--rts-button-3);
}

/* ==== Job Cta Card ==== */
.job__cta__card {
  background-color: var(--rts-gray);
  position: relative;
  overflow: hidden;
}
.job__cta__card .cta__content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  width: 55%;
  color: var(--rts-white);
  margin-left: 50px;
}
@media screen and (max-width: 992px) {
  .job__cta__card .cta__content {
    width: 100%;
    height: auto;
    padding: 30px 0;
  }
}
.job__cta__card .cta__content::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: -10px;
  background-image: url("../img/hom-4/cta__shape.svg");
  top: 0;
  z-index: -1;
}
@media screen and (max-width: 1400px) {
  .job__cta__card .cta__content::after {
    width: 105%;
  }
}
@media screen and (max-width: 1200px) {
  .job__cta__card .cta__content::after {
    width: 125%;
  }
}
@media screen and (max-width: 992px) {
  .job__cta__card .cta__content::after {
    width: 110%;
    right: auto;
    left: -5%;
  }
}
@media screen and (max-width: 768px) {
  .job__cta__card .cta__content::after {
    width: 130%;
    right: auto;
    left: -10%;
  }
}
@media screen and (max-width: 1200px) {
  .job__cta__card .cta__content {
    margin-left: 0;
  }
}
@media screen and (max-width: 1400px) {
  .job__cta__card .cta__content .ml-100 {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .job__cta__card .cta__content .ml-100 {
    margin-left: 0;
  }
}
@media screen and (max-width: 992px) {
  .job__cta__card .cta__content .ml-100 {
    margin-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .job__cta__card .cta__content .ml-100 {
    margin-left: 25px;
  }
}
@media screen and (max-width: 992px) {
  .job__cta__card .cta__content .max-500 {
    max-width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .job__cta__card .cta__content .h3 {
    font-size: 40px;
  }
}
@media screen and (max-width: 992px) {
  .job__cta__card .cta__content .h3 {
    font-size: 30px;
  }
}
.job__cta__card .fill__btn {
  padding: 18px 20px;
  border-radius: 0;
  background-color: var(--rts-gray);
  border: 0;
  color: var(--rts-button-1);
  font-weight: 500;
  margin-top: 30px;
  text-transform: capitalize;
  transition: var(--transition);
}
.job__cta__card .fill__btn:hover {
  background-color: var(--rts-heading);
  color: var(--rts-white);
}

/* ==== App Section ==== */
@media screen and (max-width: 1200px) {
  .app__content__section .app__title {
    font-size: 40px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 576px) {
  .app__content__section .app__title {
    font-size: 30px;
    line-height: 1.2;
  }
}

.top__candidate__bg {
  background-image: url("../img/home-5/banner/banner__background.png");
  border-bottom-right-radius: 160px;
}
@media screen and (max-width: 992px) {
  .top__candidate__bg {
    border-bottom-right-radius: 0;
  }
}
.top__candidate__bg .rts__banner__shape {
  position: absolute;
  height: 100%;
  width: 100%;
}
.top__candidate__bg .rts__banner__shape .shape__one,
.top__candidate__bg .rts__banner__shape .shape__two {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background: linear-gradient(180deg, #58D379 0%, #44B964 100%);
}
.top__candidate__bg .rts__banner__shape .shape__one {
  position: absolute;
  left: 5%;
  bottom: 40%;
}
.top__candidate__bg .rts__banner__shape .shape__two {
  position: absolute;
  right: 5%;
  top: 15%;
}

@media screen and (max-width: 768px) {
  .top__candidate__single {
    padding: 25px !important;
  }
}
.top__candidate__single .author img {
  height: 60px;
  width: 60px;
}
.top__candidate__single .meta i {
  color: var(--rts-button-1);
}
.top__candidate__single .job__tags a {
  background-color: var(--rts-gray);
  padding: 10px;
  border-radius: 6px;
  line-height: 1;
  color: var(--rts-para);
}
@media screen and (max-width: 992px) {
  .top__candidate__single .job__tags a {
    font-size: 16px;
  }
}
.top__candidate__single .apply__btn:hover {
  background-color: var(--rts-button-1);
}

.rts__section__content.text-white .rts__section__title {
  color: var(--rts-white) !important;
}

@media screen and (max-width: 1200px) {
  .about__image img {
    width: 100%;
    border-radius: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .about__image.is__home__5 {
    position: relative !important;
  }
}

.rts__job__details .apply__btn {
  background-color: var(--rts-primary);
  color: var(--rts-white);
  padding: 20px 30px;
  border-radius: 6px;
}

.rts__tab .nav {
  border-bottom: none;
  display: flex;
  gap: 15px;
}
.rts__tab .nav .nav-link {
  padding: 15px 12px;
  background-color: var(--rts-gray);
  border: none;
  border-radius: 6px;
  font-size: 16px;
  color: var(--rts-heading);
  letter-spacing: -0.5px;
  font-weight: 500;
  line-height: 1.2;
}
.rts__tab .nav .nav-link.active {
  background-color: var(--rts-primary);
  color: var(--rts-white);
}

.job__tags.job__details__tags {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.job__tags.job__details__tags a {
  padding: 5px 10px;
  border-radius: 6px;
  background-color: var(--rts-gray);
  color: var(--rts-para);
  line-height: 1.3;
  text-transform: capitalize;
  transition: var(--transition);
  border: 1px solid #F1F1F1;
}

.video__section.height-550 {
  margin-top: -250px;
}
@media screen and (max-width: 992px) {
  .video__section.height-550 {
    margin-top: -100px;
  }
}
.video__section.height-550 .video__section__content {
  height: 550px;
}
@media screen and (max-width: 576px) {
  .video__section.height-550 .video__section__content {
    height: 100%;
  }
}
.video__section.height-550 .video__section__content img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video__section__content {
  position: relative;
}
.video__section__content::before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 0;
  top: 0;
  z-index: 1;
}
.video__section__content img {
  border-radius: 10px;
  position: relative;
}
.video__section__content .video__play__btn, .video__section__content.style__two .video__play__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.video__section__content .video__play__btn::before, .video__section__content.style__two .video__play__btn::before {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: var(--rts-white);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 992px) {
  .video__section__content .video__play__btn::before, .video__section__content.style__two .video__play__btn::before {
    height: 75px;
    width: 75px;
  }
}
@media screen and (max-width: 768px) {
  .video__section__content .video__play__btn::before, .video__section__content.style__two .video__play__btn::before {
    height: 60px;
    width: 60px;
  }
}
.video__section__content .video__play__btn::after, .video__section__content.style__two .video__play__btn::after {
  content: "";
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  animation: linear ripple 2s infinite;
}
@media screen and (max-width: 992px) {
  .video__section__content .video__play__btn::after, .video__section__content.style__two .video__play__btn::after {
    height: 90px;
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .video__section__content .video__play__btn::after, .video__section__content.style__two .video__play__btn::after {
    height: 70px;
    width: 70px;
  }
}
.video__section__content .video__play__btn i, .video__section__content.style__two .video__play__btn i {
  font-size: 30px;
  color: var(--rts-primary);
}
@media screen and (max-width: 768px) {
  .video__section__content .video__play__btn i, .video__section__content.style__two .video__play__btn i {
    font-size: 20px;
  }
}
@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
  }
}
.video__section__content.style__two .video__play__btn i {
  font-size: 20px;
  color: #7763E5;
}
.video__section__content.style__two .video__play__btn::before {
  height: 65px;
  width: 65px;
}
.video__section__content.style__two .video__play__btn::after {
  height: 78px;
  width: 78px;
}

.company__card {
  --section-bg: linear-gradient(228.42deg, #FEF2EB 0%, #F1F1F1 46.48%, #E4E0EA 100.36%);
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  background: var(--section-bg);
}
.company__card .icon {
  height: 82px;
  width: 82px;
  border-radius: 6px;
  background-color: var(--rts-white);
  display: grid;
  place-items: center;
  margin: 0 auto;
}
.company__card .icon img {
  height: 50px;
  width: 50px;
}
.company__card .apply__btn {
  width: 100%;
  background-color: var(--rts-primary);
  color: var(--rts-white);
  font-weight: 700;
  border-radius: 6px;
}

.company__link {
  color: var(--rts-heading);
  font-weight: 500;
}

.job__overview {
  background: linear-gradient(228.42deg, #FEF2EB 0%, #F1F1F1 46.48%, #E4E0EA 100.36%);
  padding: 30px;
  border-radius: 10px;
}
@media screen and (max-width: 1400px) {
  .job__overview__content {
    font-size: 16px;
  }
}
.job__overview__content li {
  padding-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid rgba(125, 128, 135, 0.2);
}
@media screen and (max-width: 576px) {
  .job__overview__content li {
    display: grid !important;
  }
}
.job__overview__content li:last-child {
  border-bottom: none;
}
.job__overview__content .left-text {
  color: var(--rts-heading);
  font-weight: 500;
  display: flex;
  gap: 15px;
  align-items: center;
}
.job__overview__content .left-text i {
  color: var(--rts-primary);
  font-size: 20px;
}
.job__overview__content .text {
  font-weight: 500;
}
.job__overview .icon {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-color: var(--rts-white);
  display: grid;
  place-items: center;
}
.job__overview .icon i {
  font-size: 18px;
  color: var(--rts-primary);
}
.job__overview.no-border-bottom li {
  border-bottom: none;
  padding-bottom: 0;
  margin-top: 0;
}
.job__overview .grid-style {
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  justify-items: start;
}
@media screen and (max-width: 1400px) {
  .job__overview .grid-style {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1200px) {
  .job__overview .grid-style {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .job__overview .grid-style {
    grid-template-columns: repeat(1, 1fr);
  }
}
.job__overview .grid-style.company__overview {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 1200px) {
  .job__overview .grid-style.company__overview {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .job__overview .grid-style.company__overview {
    grid-template-columns: repeat(1, 1fr);
  }
}
.job__overview.style__four {
  padding: 0;
  background: unset;
}
.job__overview.style__four .icon {
  background-color: var(--rts-gray);
}

.job__location .gmap iframe {
  height: 250px;
  width: 100%;
  border-radius: 10px;
}
.job__location .gmap.height-500 iframe {
  height: 500px;
}
.job__location__map .gmap iframe {
  height: 500px;
  width: 100%;
  border-radius: 10px;
}

.job__contact {
  background: linear-gradient(228.42deg, #FEF2EB 0%, #F1F1F1 46.48%, #E4E0EA 100.36%);
  padding: 30px;
  border-radius: 10px;
}
.job__contact .search__item label {
  line-height: 1;
}
.job__contact .search__item i {
  color: #939393;
  font-size: 15px;
}
.job__contact .search__item textarea {
  height: 105px;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 15px 20px;
  padding-left: 45px;
}
.job__contact .apply__btn {
  background-color: var(--rts-primary);
  color: var(--rts-white);
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .job__contact .apply__btn {
    font-size: 16px;
  }
}

.card-shadow {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
}

.company__icon.is__height__240 {
  height: 240px;
  min-width: 240px;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 16px;
}
@media screen and (max-width: 576px) {
  .company__icon.is__height__240 {
    margin: 0 auto;
  }
}
.company__icon.is__height__240 img {
  height: 130px;
  width: 130px;
}
.company__icon.recent__post {
  display: grid;
  place-items: center;
  border-radius: 6px;
  height: 75px;
  min-width: 75px;
  background-color: var(--rts-gray);
}
.company__icon.recent__post img {
  height: 45px;
  width: 45px;
}

.rts__single__employer.employer__style__two {
  padding: 30px;
  position: relative;
  border-radius: 6px;
}
.rts__single__employer.employer__style__two .apply__btn.style__two {
  padding: 12px 20px;
  line-height: inherit;
  border-radius: 6px;
  font-weight: 500;
}
.rts__single__employer.employer__style__two::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: var(--transition);
  z-index: -1;
  background: linear-gradient(228.26deg, #FEF2EB 0%, #F1F1F1 46.25%, #E4E0EA 99.86%);
  border-radius: 10px;
}
.rts__single__employer.employer__style__two:hover {
  box-shadow: none;
  background-color: unset;
}
.rts__single__employer.employer__style__two:hover::before {
  opacity: 1;
}
.rts__single__employer.employer__style__two:hover .apply__btn {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}
.rts__single__employer.employer__style__two:hover .icon {
  background-color: var(--rts-white);
}

.review__card {
  background-color: var(--rts-white);
  padding: 25px 30px;
  box-shadow: 0px 4px 40px rgba(166, 166, 166, 0.16);
  border-radius: 10px;
}
.review__card .author__thumb {
  height: 60px;
  width: 60px;
  border-radius: 6px;
}
.review__card .rating i {
  color: var(--rts-button-2);
  font-size: 16px;
}

.rts__author__card.style__default {
  transition: var(--transition);
  border: 1px solid #DCDDDF;
  position: relative;
}
.rts__author__card.style__default > * {
  position: relative;
  z-index: 2;
}
.rts__author__card.style__default::before {
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  border-radius: inherit;
  z-index: 1;
  opacity: 0;
  border: 0;
  transition: var(--transition);
}
.rts__author__card.style__default:hover {
  border-color: transparent;
}
.rts__author__card.style__default:hover::before {
  opacity: 1;
}
.rts__author__card.style__default:hover .job__tags a {
  background-color: var(--rts-white);
}
.rts__author__card.style__default:hover .job__tags a:hover {
  color: inherit;
}
.rts__author__card.style__default:hover .apply__btn {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  border-color: var(--rts-button-1);
}
.rts__author__card.style__default i {
  color: var(--rts-para);
}
.rts__author__card.style__default .job__tags a {
  padding: 5px 15px;
  border-radius: 20px;
}
.rts__author__card.style__default .apply__btn {
  padding: 10px 16px;
}

.rts__author__card__big {
  border: 1px solid #DCDDDF;
  box-shadow: unset !important;
}
.rts__author__card__big:hover {
  border-color: transparent;
}
.rts__author__card__big:hover .job__tags a {
  border-radius: 25px !important;
}
.rts__author__card__big:hover .job__tags.style__default a {
  border-radius: 6px !important;
  color: var(--rts-para);
}
.rts__author__card__big .author__icon img {
  height: 92px;
  min-width: 92px;
  border-radius: 10px;
}
.rts__author__card__big .author__icon.small__thumb img {
  height: 60px;
  min-width: 60px;
  border-radius: 6px;
}
.rts__author__card__big .job__tags a {
  border-radius: 25px;
  background-color: var(--rts-gray);
  font-weight: 500;
}

.author__icon img {
  height: 122px;
  min-width: 122px;
  border-radius: 10px;
}
.author__icon.small__thumb img {
  height: 60px;
  min-width: 60px;
}

.review__form .rating i {
  color: var(--rts-button-2);
}

.candidate__info .grid-style {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 1200px) {
  .candidate__info .grid-style {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .candidate__info .grid-style {
    grid-template-columns: repeat(1, 1fr);
  }
}

.top__candidate__single .apply__btn {
  min-width: 150px;
}

.px-20 {
  padding: 0 20px;
}

.top-30 {
  top: 30px !important;
}

.rts__dropdown {
  padding: 15px 15px;
  min-width: 230px;
  max-width: 360px;
}
.rts__dropdown li a {
  font-weight: 500;
  padding: 6px 15px;
  transition: var(--transition);
}
.rts__dropdown li a:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}

.notification__dropdown {
  padding: 0;
}

.rts__dropdown__content .notification__header {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--rts-border);
  padding-bottom: 15px;
}
.rts__dropdown__content .notification__header h6 {
  font-size: 18px;
  color: var(--rts-para);
}
.rts__dropdown__content .notification__header a i {
  color: var(--rts-para);
  font-size: 18px;
}
.rts__dropdown__content .notification__date {
  padding: 5px 15px;
  background-color: var(--rts-gray);
  display: inline-block;
  width: 100%;
  font-weight: 500;
}
.rts__dropdown__content .single__notification {
  padding: 15px 15px;
  border-bottom: 1px solid var(--rts-border);
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: var(--transition);
}
.rts__dropdown__content .single__notification:last-child {
  border-bottom: 0;
}
.rts__dropdown__content .single__notification:hover {
  background-color: var(--rts-gray);
}
.rts__dropdown__content .single__notification .notification__image img {
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.rts__dropdown__content .single__notification .notification__content p {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--rts-para);
}
.rts__dropdown__content .single__notification .time {
  font-size: 14px;
  font-weight: 500;
  min-width: max-content;
}

.sidebar__action, .message__action {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--rts-button-3);
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  max-width: max-content;
}

.user__info .user__image.if__employer {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: var(--rts-gray);
  display: grid;
  place-items: center;
}
.user__info .user__image.if__employer img {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .mobile__padding {
    padding: 25px 20px;
  }
}

.template-dashboard {
  background: linear-gradient(228.24deg, #FEF2EB 0%, #F1F1F1 46.23%, #E4E0EA 99.82%);
}

.rts__dashboard__header {
  border-bottom: 1px solid rgba(125, 128, 135, 0.2);
  z-index: 9;
}

.dropdown__icon {
  cursor: pointer;
}
.dropdown__icon i {
  color: var(--rts-button-3);
}

.notification__icon {
  position: relative;
  cursor: pointer;
}
.notification__icon i {
  font-size: 22px;
  color: var(--rts-button-3);
}
.notification__icon .notification__count {
  height: 16px;
  min-width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  color: var(--rts-white);
  position: absolute;
  top: 0;
  background-color: var(--rts-button-1);
  left: 8px;
}

.dashboard__left::-webkit-scrollbar {
  width: 6px;
}

.dashboard__left::-webkit-scrollbar-thumb {
  background: var(--rts-button-1);
  border-radius: 5px;
}

.dashboard__left {
  min-width: 360px;
  background: var(--rts-white);
  padding: 30px;
  max-width: 100%;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  transition: var(--transition);
  margin-top: 100px;
}
@media screen and (max-width: 992px) {
  .dashboard__left {
    margin-left: -360px;
  }
}
.dashboard__left.active {
  margin-left: 0;
}
.dashboard__left .dash__menu ul {
  list-style: none;
  padding: 0;
}
.dashboard__left .dash__menu ul li {
  margin-bottom: 10px;
}
.dashboard__left .dash__menu ul li a {
  padding: 12px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #4B5563;
  font-weight: 500;
  transition: var(--transition);
  position: relative;
  z-index: 1;
}
.dashboard__left .dash__menu ul li a::before {
  content: "";
  background: linear-gradient(228.24deg, #FEF2EB 0%, #F1F1F1 46.23%, #E4E0EA 99.82%);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: var(--transition);
}
.dashboard__left .dash__menu ul li a:hover {
  color: var(--rts-button-3);
}
.dashboard__left .dash__menu ul li a:hover::before {
  opacity: 1;
}
.dashboard__left .dash__menu ul li a:hover svg path[fill] {
  fill: var(--rts-button-1);
}
.dashboard__left .dash__menu ul li a:hover svg path[stroke] {
  stroke: var(--rts-button-1);
}
.dashboard__left .dash__menu ul li a.active {
  background: linear-gradient(228.24deg, #FEF2EB 0%, #F1F1F1 46.23%, #E4E0EA 99.82%);
  color: var(--rts-button-3);
}
.dashboard__left .dash__menu ul li a.active svg path[fill] {
  fill: var(--rts-button-1);
}
.dashboard__left .dash__menu ul li a.active svg path[stroke] {
  stroke: var(--rts-button-1);
}
.dashboard__left .dash__logout {
  margin-top: 500px;
  position: relative;
  margin-bottom: 100px;
}
@media screen and (max-width: 992px) {
  .dashboard__left .dash__logout {
    margin-top: 300px;
  }
}
.dashboard__left .dash__logout .logout__image {
  border-radius: 16px;
  background-color: var(--rts-gray);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 20px;
}
.dashboard__left .dash__logout a.logout__btn {
  background: var(--rts-button-1);
  color: var(--rts-white);
  padding: 20px 0;
  text-align: center;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  line-height: 1;
  transition: var(--transition);
}
.dashboard__left .dash__logout a.logout__btn:hover {
  color: var(--rts-white);
  background: var(--rts-button-3);
}

.p-30 {
  padding: 30px;
}

.dashboard__right {
  padding: 30px;
  width: calc(100% - 360px);
  margin-left: 360px;
  margin-top: 100px;
}
@media screen and (max-width: 992px) {
  .dashboard__right {
    margin-left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .dashboard__right {
    padding: 20px;
  }
}

.profile__view {
  min-width: calc(100% - 40%);
}
@media screen and (max-width: 1400px) {
  .profile__view {
    min-width: 100%;
  }
}

.overview__content {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 1400px) {
  .overview__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .overview__content {
    grid-template-columns: repeat(1, 1fr);
  }
}

.single__overview {
  background-color: var(--rts-white);
  padding: 30px 40px;
  border-radius: 10px;
  display: flex;
  gap: 30px;
  flex: 1;
  align-items: center;
}
@media screen and (min-width: 1399px) and (max-width: 1680px) {
  .single__overview {
    padding: 20px;
    gap: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .single__overview {
    padding: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .single__overview .content h5 {
    font-size: 25px;
  }
}
.single__overview .icon {
  height: 70px;
  min-width: 70px;
  border-radius: 10px;
  background-color: var(--rts-gray);
  display: grid;
  place-content: center;
}

.radius-16 {
  border-radius: 16px;
}

.chart__and__notification {
  display: flex;
}
@media screen and (max-width: 1400px) {
  .chart__and__notification {
    flex-wrap: wrap;
  }
}

.my__profile__tab {
  padding: 30px;
}
.my__profile__tab .nav-tabs {
  border-bottom: none;
}
.my__profile__tab .nav-tabs .nav-link {
  color: var(--rts-para);
  padding: 15px;
  border: none;
  border-radius: 6px;
  line-height: 100%;
}
.my__profile__tab .nav-tabs .nav-link.active {
  background-color: var(--rts-gray);
  color: var(--rts-button-3);
}

.profile__view__tab {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.profile__view__tab .nav-link {
  padding: 10px;
  transition: var(--transition);
  border-radius: 6px;
}
@media screen and (max-width: 576px) {
  .profile__view__tab .nav-link {
    font-size: 14px;
  }
}
.profile__view__tab .nav-link.active {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}

.profile__view,
.notification__area {
  padding: 30px;
  border-radius: 16px;
}
@media screen and (max-width: 1400px) {
  .profile__view,
.notification__area {
    width: 100%;
  }
}

.notification__list .notification__item {
  display: flex;
  gap: 30px;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--rts-border);
}
@media screen and (max-width: 576px) {
  .notification__list .notification__item {
    flex-wrap: wrap;
  }
}
.notification__list .notification__item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.notification__list .notification__item .icon {
  height: 70px;
  min-width: 70px;
  border-radius: 6px;
  background-color: var(--rts-gray);
  display: grid;
  place-content: center;
}
.notification__list .notification__item .icon img {
  height: 40px;
  width: 40px;
}
.notification__list .notification__item .icon.is__image img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 6px;
}
.notification__list .notification__item .content a {
  color: var(--rts-button-3);
}
.notification__list .notification__item .content .time {
  margin-top: 10px;
}

.info__top {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
}
@media screen and (max-width: 576px) {
  .info__top {
    flex-wrap: wrap;
  }
}
.info__top .author__image {
  background: var(--rts-gray);
  border-radius: 16px;
}
.info__top .author__image img {
  height: 160px;
  width: 160px;
  border-radius: 6px;
}
.info__top .delete__data {
  height: 52px;
  min-width: 52px;
  border-radius: 6px;
  background-color: var(--rts-gray);
  display: grid;
  place-content: center;
  cursor: pointer;
  color: var(--rts-button-3);
}

.file-upload {
  position: relative;
  display: inline-block;
}

.file-upload__label {
  display: block;
  padding: 10px 15px;
  color: #fff;
  background: var(--rts-button-1);
  border-radius: 0.4em;
  transition: background 0.3s;
}
.file-upload__label:hover {
  cursor: pointer;
  background: #000;
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}

.info__field {
  display: flex;
  flex-direction: column;
  gap: calc(40px / 2);
}
.info__field .rt-input-group label {
  display: block;
  margin-bottom: 15px;
  color: var(--rts-button-3);
  margin-top: 15px;
  text-transform: capitalize;
}
.info__field .rt-input-group label:first-child {
  margin-top: 0;
}
@media screen and (max-width: 576px) {
  .info__field .rt-input-group label {
    margin-top: 15px;
  }
}
.info__field .rt-input-group input,
.info__field .rt-input-group .form-select,
.info__field .rt-input-group textarea {
  padding: 15px 20px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--rts-border);
}
@media screen and (max-width: 768px) {
  .info__field .rt-input-group input,
.info__field .rt-input-group .form-select,
.info__field .rt-input-group textarea {
    padding: 12px 20px;
    font-size: 16px;
  }
}
.info__field .rt-input-group input:focus,
.info__field .rt-input-group .form-select:focus,
.info__field .rt-input-group textarea:focus {
  box-shadow: none;
}
.info__field .rt-input-group textarea {
  height: 150px;
}

.user__location iframe {
  height: 390px;
  width: 100%;
  border-radius: 10px;
}

.added__social__link {
  border: 1px solid var(--rts-border);
  padding: 15px 20px;
  max-width: max-content;
  color: var(--rts-button-3);
  border-radius: 6px;
  display: inline-block;
  transition: var(--transition);
}
@media screen and (max-width: 768px) {
  .added__social__link {
    line-height: 1.2;
  }
}
.added__social__link:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}

.select__image {
  position: relative;
}

.file-upload {
  position: relative;
  display: inline-block;
}

.file-upload__label__two {
  display: block;
  padding: 10px 15px;
  color: var(--rts-button-3);
  background: var(--rts-gray);
  border-radius: 0.4em;
  transition: background 0.3s;
  height: 500px;
  width: 755px;
  text-align: center;
  position: relative;
  border: 2px dotted rgba(11, 13, 40, 0.5);
}
@media screen and (max-width: 1200px) {
  .file-upload__label__two {
    width: 600px;
  }
}
@media screen and (max-width: 768px) {
  .file-upload__label__two {
    width: 500px;
    height: 400px;
  }
}
@media screen and (max-width: 576px) {
  .file-upload__label__two {
    width: 300px;
    height: 300px;
  }
}
.file-upload__label__two:hover {
  cursor: pointer;
  background: #000;
  color: var(--rts-white);
}
.file-upload__label__two i {
  font-size: 82px;
  display: block;
  margin-bottom: 30px;
  color: var(--rts-button-1);
}
.file-upload__label__two span {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.file-upload__input__two {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}

.cv__included {
  flex-wrap: wrap;
}
.cv__included .single__item {
  max-width: 360px;
  min-width: 360px;
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid var(--rts-border);
}
@media screen and (max-width: 576px) {
  .cv__included .single__item {
    min-width: 100%;
  }
}
.cv__included .single__item i {
  color: var(--rts-button-1);
  cursor: pointer;
}
.cv__included .file__type {
  font-weight: 600;
  color: var(--rts-button-3);
}

.template-dashboard .accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.template-dashboard .accordion .accordion-item {
  border: 0;
}
.template-dashboard .accordion .accordion-item button {
  background-color: var(--rts-gray);
  border-radius: 0;
  font-weight: 600;
  font-size: 20px;
  border-radius: 6px;
  padding: 20px 30px;
}
.template-dashboard .accordion .accordion-item button:focus {
  box-shadow: none;
}
.template-dashboard .accordion .accordion-item button:not(.collapsed) {
  box-shadow: none;
}

.my__skillset {
  margin-bottom: 30px;
  padding: 15px 20px;
  background-color: var(--rts-gray);
  border-radius: 6px;
}
.my__skillset .skill__tags {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.my__skillset .skill__tags li {
  background-color: var(--rts-white);
  padding: 10px 15px;
  border-radius: 4px;
  max-width: max-content;
  gap: 15px;
  cursor: pointer;
  display: flex;
}
.my__skillset .skill__tags li i {
  color: var(--rts-button-1);
  cursor: pointer;
}
.my__skillset .skill__tags li .skill__item {
  font-size: 20px;
  font-weight: 600;
}
.my__skillset .skill__tags li .skill__item__add i {
  color: var(--rts-white);
}
.my__skillset .skill__tags li:last-child {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}

.single__portfolio {
  position: relative;
}
.single__portfolio .delete__icon {
  position: absolute;
  top: 20px;
  right: 35px;
}
.single__portfolio .delete__icon button {
  height: 40px;
  min-width: 40px;
  display: grid;
  place-items: center;
  background-color: var(--rts-white);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  transition: var(--transition);
}
.single__portfolio .delete__icon button i {
  color: var(--rts-button-1);
}
.single__portfolio .delete__icon button:hover {
  background-color: var(--rts-button-1);
}
.single__portfolio .delete__icon button:hover i {
  color: var(--rts-white);
}
.single__portfolio .portfolio__thumb img {
  border-radius: 6px;
}

.applied__job__info {
  background-color: var(--rts-white);
  padding: 30px;
}

.job__filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.search__job {
  min-width: 330px;
}
@media screen and (max-width: 576px) {
  .search__job {
    min-width: 100%;
  }
}
.search__job input {
  background-color: var(--rts-gray);
  border: 0;
  padding: 13px 40px;
  border-radius: 4px;
  color: var(--rts-button-3);
  width: 100%;
}
.search__job i {
  position: absolute;
  left: 25px;
  top: 50%;
  color: var(--rts-button-3);
  transform: translate(-50%, -50%);
}

.filter__job .filter__select {
  background-color: var(--rts-gray);
  min-width: 190px;
  border: none;
  padding: 5px 20px;
}

.applied__job__list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}

.single__applied__job {
  display: flex;
  padding: 30px;
  border: 1px solid var(--rts-border);
  border-radius: 10px;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__applied__job {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 1400px) {
  .single__applied__job {
    gap: 20px;
  }
}
.single__applied__job__content {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media screen and (max-width: 768px) {
  .single__applied__job__content {
    flex-wrap: wrap;
  }
}
.single__applied__job .icon {
  height: 122px;
  min-width: 122px;
  background-color: var(--rts-gray);
  border-radius: 50%;
  display: grid;
  place-content: center;
}
@media screen and (max-width: 1400px) {
  .single__applied__job .icon {
    display: none;
  }
}
.single__applied__job .icon img {
  height: 75px;
  width: 75px;
}
.single__applied__job .content__flex {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.single__applied__job .content a h6 {
  margin-bottom: 10px;
}
.single__applied__job .content__info {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.single__applied__job .content__info span {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.single__applied__job .content__info span i {
  color: var(--rts-button-3);
}
.single__applied__job .tags {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.single__applied__job .tags a {
  color: var(--rts-para);
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid var(--rts-border);
}
.single__applied__job .action {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.single__applied__job .action__btn {
  height: 42px;
  width: 46px;
  border-radius: 6px;
  border: 1px solid var(--rts-border);
  display: grid;
  place-items: center;
  transition: var(--transition);
}
.single__applied__job .action__btn:hover {
  background-color: var(--rts-button-1);
  border-color: var(--rts-button-1);
}
.single__applied__job .action__btn:hover svg path[fill] {
  fill: var(--rts-white);
}
.single__applied__job .action__btn:hover svg path[stroke] {
  stroke: var(--rts-white);
}
.single__applied__job .action__operation {
  padding: 12px 18px;
  line-height: 1;
  border-radius: 6px;
  background-color: transparent;
}
.single__applied__job .action__operation.pending {
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
  color: var(--rts-button-1);
  transition: var(--transition);
}
.single__applied__job .action__operation.pending:hover {
  background: var(--rts-button-1);
  color: var(--rts-white);
}
.single__applied__job .action__operation.rejected {
  background: var(--rts-button-1);
  color: var(--rts-white);
}
.user__list__as__messanger {
  background-color: var(--rts-white);
  padding: 30px;
  transition: var(--transition);
}
.user__list__as__messanger .chat__user__list .single__chat__person::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -4%;
  width: 108%;
  height: 1px;
  background-color: var(--rts-border);
}
.user__list__as__messanger .chat__user__list .single__chat__person:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.candidate__message {
  padding: 30px;
  border-radius: 16px;
  background-color: var(--rts-white);
}
@media screen and (max-width: 768px) {
  .candidate__message {
    padding: 15px;
  }
}
.candidate__message__content {
  border: 1px solid var(--rts-border);
}
.candidate__message .message__left {
  max-width: 440px;
  min-width: 440px;
  padding: 30px;
  border-right: 1px solid var(--rts-border);
  overflow: hidden;
  transition: var(--transition);
}
@media screen and (max-width: 1400px) {
  .candidate__message .message__left {
    max-width: 380px;
    min-width: 380px;
    padding: 14px;
    display: none;
  }
}
.candidate__message .message__filter {
  margin-top: 20px;
}
.candidate__message .message__filter ul {
  display: flex;
  gap: 20px;
  align-items: center;
}
.candidate__message .message__filter ul li a {
  color: var(--rts-para);
}
.candidate__message .message__filter ul li a.active {
  color: var(--rts-button-3);
}

.chat__user__list {
  margin-top: 30px;
}
.chat__user__list .single__chat__person {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;
  transition: var(--transition);
}
@media screen and (max-width: 576px) {
  .chat__user__list .single__chat__person {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.chat__user__list .single__chat__person::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10%;
  width: 120%;
  height: 1px;
  background-color: var(--rts-border);
}
.chat__user__list .single__chat__person:last-child::after {
  display: none;
}
.chat__user__list .single__chat__person .avater img {
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
}
.chat__user__list .single__chat__person .chat__person__meta {
  cursor: pointer;
}
.chat__user__list .single__chat__person .chat__person__meta p {
  font-size: 16px;
}
.chat__user__list .right__count {
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: end;
}
@media screen and (max-width: 576px) {
  .chat__user__list .right__count {
    display: none;
  }
}
.chat__user__list .right__count span {
  font-size: 16px;
  font-weight: 500;
  color: var(--rts-para);
}
.chat__user__list .right__count span.count {
  height: 16px;
  min-width: 16px;
  border-radius: 50%;
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  display: flex;
  justify-content: center;
  font-size: 8px;
  align-items: center;
}

.candidate__message__content {
  display: flex;
}

.message__content {
  width: 100%;
  border-radius: 0;
  background-color: rgba(125, 128, 135, 0.3);
}
.message__content .chat__header {
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: var(--rts-white);
  padding: 20px 30px;
}
.message__content .chat__header .avatar {
  height: 64px;
  width: 64px;
  place-items: center;
  display: grid;
  border-radius: 50%;
  background-color: var(--rts-gray);
}
.message__content .chat__header .avatar img {
  height: 45px;
  width: 45px;
  object-fit: cover;
}
.message__content .chat__content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 45px;
}
@media screen and (max-width: 576px) {
  .message__content .chat__content {
    padding: 15px;
  }
}
.message__content .chat__content .msg {
  display: flex;
  gap: 20px;
  width: 50%;
  align-items: end;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .message__content .chat__content .msg {
    width: 80%;
  }
}
@media screen and (max-width: 1400px) {
  .message__content .chat__content .msg {
    width: 80%;
  }
}
@media screen and (max-width: 576px) {
  .message__content .chat__content .msg {
    flex-wrap: wrap;
    width: 100%;
  }
}
.message__content .chat__content .msg .avatar {
  background-color: var(--rts-white);
  height: 46px;
  min-width: 46px;
  border-radius: 50%;
  place-items: center;
  display: grid;
}
.message__content .chat__content .msg .avatar img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.message__content .chat__content .msg .content p {
  background-color: var(--rts-white);
  padding: 20px;
  border-radius: 20px;
  border-bottom-left-radius: 0;
}
.message__content .chat__content .msg .content span.time {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}
.message__content .chat__content .msg.receiver {
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 100%;
}
.message__content .chat__content .msg.receiver .content {
  width: 40%;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .message__content .chat__content .msg.receiver .content {
    width: 80%;
  }
}
@media screen and (max-width: 1400px) {
  .message__content .chat__content .msg.receiver .content {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .message__content .chat__content .msg.receiver .content {
    width: 100%;
  }
}
.message__content .chat__content .msg.receiver .content p {
  background-color: var(--rts-button-1);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
  color: var(--rts-white);
}
.message__content .chat__content .msg.receiver .content .time {
  text-align: right;
}
.message__content .chat__content .input__msg {
  position: relative;
}
.message__content .chat__content .input__msg input[type=text] {
  width: 100%;
  border: 1px solid rgba(125, 128, 135, 0.5);
  padding: 18px 20px;
  position: relative;
}
.message__content .chat__content .input__msg label {
  position: absolute;
  top: 15px;
  right: 110px;
  cursor: pointer;
}
.message__content .chat__content .input__msg .message__btn {
  padding: 10px 20px;
  border-radius: 6px;
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  position: absolute;
  right: 10px;
  top: 12px;
}

.candidate__meeting__content {
  background-color: var(--rts-white);
  border-radius: 16px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.single__meeting__item {
  display: flex;
  padding: 30px;
  border: 1px solid var(--rts-border);
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}
@media screen and (max-width: 1200px) {
  .single__meeting__item {
    flex-wrap: wrap;
  }
}
.single__meeting__item .calender {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--rts-gray);
  min-width: 122px;
  height: 122px;
  text-align: center;
  line-height: 1;
  gap: 8px;
}
.single__meeting__item .calender .month {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  padding: 13px 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.single__meeting__item .calender .day {
  font-size: 28px;
  font-weight: 600;
  color: var(--rts-button-3);
}
.single__meeting__item .calender .year {
  font-size: 20px;
  font-weight: 500;
}
.single__meeting__item .time {
  display: flex;
  gap: 50px;
  align-items: center;
  margin-left: 60px;
  flex-wrap: wrap;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__meeting__item .time {
    margin-left: 25px;
    gap: 25px;
  }
}
@media screen and (max-width: 1400px) {
  .single__meeting__item .time {
    margin-left: 0;
    gap: 15px;
  }
}
.single__meeting__item .time span {
  display: flex;
  gap: 10px;
  align-items: center;
}
.single__meeting__item .content h6 {
  font-weight: 600;
}
@media screen and (max-width: 1400px) {
  .single__meeting__item .content h6 {
    font-size: 20px;
  }
}
.single__meeting__item .meeting__action {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__meeting__item .meeting__action {
    gap: 15px;
  }
}
.single__meeting__item .meeting__action .action__item {
  position: relative;
  height: 42px;
  width: 42px;
  display: grid;
  place-items: center;
  border: 1px solid var(--rts-border);
  border-radius: 6px;
  transition: var(--transition);
}
.single__meeting__item .meeting__action .action__item .notification__item {
  height: 14px;
  width: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: var(--rts-button-1);
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: var(--rts-white);
  font-size: 6px;
}
.single__meeting__item .meeting__action .action__item:hover {
  border-color: var(--rts-button-1);
  background-color: var(--rts-button-1);
}
.single__meeting__item .meeting__action .action__item:hover svg path[fill] {
  fill: var(--rts-white);
}
.single__meeting__item .meeting__action .action__item:hover svg path[stroke] {
  stroke: var(--rts-white);
}
.single__meeting__item .meeting__action .action__item:hover .notification__item {
  background-color: var(--rts-white);
  color: var(--rts-button-3);
}

.single__meeting__left {
  display: flex;
  gap: 30px;
  align-items: center;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__meeting__left {
    gap: 15px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1400px) {
  .single__meeting__left {
    gap: 15px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__meeting__left .content h6 {
    font-size: 22px;
  }
}

.change__password {
  min-height: 100vh;
  width: 100%;
  background-color: var(--rts-white);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media screen and (max-width: 1200px) {
  .change__password {
    min-height: 100vh;
  }
}
@media screen and (max-width: 768px) {
  .change__password {
    min-height: 100%;
  }
}

.password__change__form {
  min-width: 630px;
  margin: 0 auto;
  padding: 60px;
  border: 1px solid var(--rts-border);
  border-radius: 20px;
}
@media screen and (max-width: 1200px) {
  .password__change__form {
    min-width: calc(100% - 60px);
  }
}
@media screen and (max-width: 768px) {
  .password__change__form {
    min-width: calc(100% - 120px);
    padding: 30px;
    border: 0;
  }
}
.password__change__form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.password__change__form .rts-input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.password__change__form .rts-input-group label {
  font-weight: 500;
  font-size: 16px;
  color: var(--rts-button-3);
}
.password__change__form .rts-input-group input {
  border: 1px solid rgba(115, 116, 119, 0.3);
  border-radius: 4px;
  padding: 16px 20px;
  padding-left: 50px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: var(--rts-button-3);
}
.password__change__form .rts-input-group .input-box {
  position: relative;
}
.password__change__form .rts-input-group .password__icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);
}
.password__change__form .rts__btn {
  max-width: max-content;
  margin-top: 20px;
  border-radius: 4px;
  padding: 18px 25px;
}
.password__change__form .gray__btn {
  background-color: #F4F6F5;
  color: var(--rts-button-3);
}

.candidate__job__alerts {
  padding-top: 40px;
  overflow-y: scroll;
  cursor: pointer;
}
.candidate__job__alerts .thead-dark {
  border-bottom: 1px solid var(--rts-border);
}
.candidate__job__alerts .thead-dark th {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.last__col {
  text-align: right;
}

.rts__table__body .single__data__table {
  border-bottom: 1px solid var(--rts-border);
}
.rts__table__body .single__data__table td,
.rts__table__body .single__data__table th {
  vertical-align: middle;
  color: var(--rts-para);
  padding-top: 20px;
  padding-bottom: 30px;
  min-width: 265px;
}
.rts__table__body .single__data__table .data__title {
  font-size: 24px;
  font-weight: 600;
  color: var(--rts-button-3);
}
.rts__table__body .alert__info h6 {
  margin-bottom: 15px;
  color: var(--rts-button-1);
}
.rts__table__body .alert__info p {
  color: var(--rts-para);
}
.rts__table__body .alert__info .tags {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.rts__table__body .alert__info .tags a {
  border: 1px solid var(--rts-border);
  padding: 10px;
  border-radius: 6px;
  color: var(--rts-para);
  line-height: 1;
  transition: var(--transition);
}
.rts__table__body .alert__info .tags a:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
  border-color: var(--rts-button-1);
}

.data__action {
  display: flex;
  justify-content: end;
  align-items: center;
}
.data__action .action {
  display: flex;
  gap: 15px;
  align-items: center;
}
.data__action .action .action__btn {
  height: 42px;
  width: 46px;
  border-radius: 6px;
  border: 1px solid var(--rts-border);
  display: grid;
  place-items: center;
  transition: var(--transition);
}
.data__action .action .action__btn:hover {
  background-color: var(--rts-button-1);
  border-color: var(--rts-button-1);
}
.data__action .action .action__btn:hover svg path[fill] {
  fill: var(--rts-white);
}
.data__action .action .action__btn:hover svg path[stroke] {
  stroke: var(--rts-white);
}

.rt-input-group span {
  display: block;
  margin-bottom: 15px;
  margin-top: -10px;
}
.rt-input-group .position-relative {
  position: relative;
}
.rt-input-group .position-relative .rts__btn {
  background-color: var(--rts-button-1);
  padding: 12px 14px;
  border-radius: 6px;
  color: var(--rts-white);
  position: absolute;
  right: 10px;
  transform: translate(0, -55%);
}
@media screen and (max-width: 768px) {
  .rt-input-group .position-relative .rts__btn {
    top: -4px;
    padding: 8px 12px;
    right: 5px;
  }
}

.info__field .rts__btn {
  margin-top: 30px;
  max-width: max-content;
  padding: 18px 26px;
  margin-left: 15px;
  border-radius: 6px;
}
@media screen and (max-width: 768px) {
  .info__field .rts__btn {
    padding: 15px 20px;
  }
}

.middle__content {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media screen and (max-width: 576px) {
  .middle__content {
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

.job__post {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media screen and (max-width: 576px) {
  .job__post .icon__text {
    display: none;
  }
}
.job__post .icon__text i {
  color: var(--rts-button-3);
}
.job__post .content span {
  display: block;
}

.applied__status span {
  border: 1px solid var(--rts-border);
  border-radius: 6px;
  padding: 5px 10px;
}

.package__selection {
  background-color: var(--rts-white);
  padding: 30px;
  border-radius: 16px;
  display: flex;
  gap: 30px;
}
@media screen and (max-width: 768px) {
  .package__selection {
    display: grid;
  }
}

.single__package__selection {
  padding: 30px;
  border-radius: 10px;
  background-color: var(--rts-gray);
  display: flex;
  flex: 1;
}
.single__package__selection .form-selection {
  display: none;
}
.single__package__selection .form-selection-label {
  position: relative;
  cursor: pointer;
  margin-left: 50px;
}
.single__package__selection .form-selection-label::before {
  content: "";
  -webkit-appearance: none;
  background-color: var(--rts-white);
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  left: -50px;
  top: 50%;
  transform: translate(0, -50%);
  border: 2px solid var(--rts-button-1);
}
.single__package__selection .form-selection[type=radio]:checked + .form-selection-label::before {
  background-color: var(--rts-button-1);
  border: 4px solid var(--rts-white);
  box-shadow: none;
  height: 28px;
  width: 28px;
}

.dash__pricing__plan {
  background-color: var(--rts-white);
  padding: 30px;
  border-radius: 16px;
}

.action__operation {
  padding: 12px 18px;
  line-height: 1;
  border-radius: 6px;
  background-color: transparent;
}
.action__operation.pending {
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
  color: var(--rts-button-1);
  transition: var(--transition);
}
.action__operation.pending:hover {
  background: var(--rts-button-1);
  color: var(--rts-white);
}

.short__list__candidate {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.shortlist__action {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .shortlist__action {
    gap: 10px;
  }
}
@media screen and (max-width: 1400px) {
  .shortlist__action {
    gap: 10px;
  }
}
@media screen and (max-width: 768px) {
  .shortlist__action {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.shortlist__action .action__item {
  position: relative;
  height: 42px;
  width: 42px;
  display: grid;
  place-items: center;
  border: 1px solid var(--rts-border);
  border-radius: 6px;
  transition: var(--transition);
}
.shortlist__action .action__item:hover {
  border-color: var(--rts-button-1);
  background-color: var(--rts-button-1);
}
.shortlist__action .action__item:hover svg path[fill] {
  fill: var(--rts-white);
}
.shortlist__action .action__item:hover svg path[stroke] {
  stroke: var(--rts-white);
}
.shortlist__action .action__item .notification__item {
  height: 14px;
  width: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: var(--rts-button-1);
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: var(--rts-white);
  font-size: 6px;
}
.shortlist__action .action__item__long {
  border: 1px solid var(--rts-border);
  border-radius: 6px;
  transition: var(--transition);
  padding: 12px 15px;
  display: flex;
  gap: 10px;
  color: var(--rts-para);
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .shortlist__action .action__item__long {
    font-size: 16px;
  }
}
.shortlist__action .action__item__long:hover {
  background-color: var(--rts-button-1);
  color: var(--rts-white);
}
.shortlist__action .action__item__long:hover svg path[fill] {
  fill: var(--rts-white);
}
.shortlist__action .action__item__long:hover svg path[stroke] {
  stroke: var(--rts-white);
}

.single__shortlist__item {
  display: flex;
  justify-content: space-between;
  background-color: var(--rts-white);
  padding: 30px;
  border-radius: 16px;
  flex-wrap: wrap;
  gap: 30px;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__shortlist__item {
    gap: 10px;
  }
}
@media screen and (max-width: 1400px) {
  .single__shortlist__item {
    font-size: 16px;
    gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .single__shortlist__item {
    gap: 20px;
  }
}
.single__shortlist__item .author__info {
  display: flex;
  gap: 60px;
  align-items: center;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__shortlist__item .author__info {
    gap: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .single__shortlist__item .author__info {
    gap: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .single__shortlist__item .author__info {
    gap: 40px;
  }
}
@media screen and (max-width: 768px) {
  .single__shortlist__item .author__info {
    gap: 20px;
    flex-wrap: wrap;
  }
}
.single__shortlist__item .author__meta {
  display: flex;
  gap: 30px;
  align-items: center;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__shortlist__item .author__meta {
    gap: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .single__shortlist__item .author__meta {
    gap: 15px;
  }
}
@media screen and (max-width: 576px) {
  .single__shortlist__item .author__meta {
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media screen and (max-width: 768px) {
  .single__shortlist__item .author__meta {
    gap: 15px;
  }
}
.single__shortlist__item .author__meta .author__image {
  height: 92px;
  width: 92px;
  border-radius: 6px;
}
.single__shortlist__item .author__meta .author__image img {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .single__shortlist__item .author__meta .author__name h6 {
    font-size: 22px;
  }
}
@media screen and (max-width: 1400px) {
  .single__shortlist__item .author__meta .author__name h6 {
    font-size: 22px;
  }
}
.single__shortlist__item .author__info__list {
  display: flex;
  gap: 60px;
}
@media screen and (max-width: 1400px) {
  .single__shortlist__item .author__info__list {
    gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .single__shortlist__item .author__info__list {
    font-size: 15px;
    gap: 30px;
  }
}
@media screen and (max-width: 576px) {
  .single__shortlist__item .author__info__list {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.single__shortlist__item .author__info__list span {
  display: flex;
  gap: 10px;
  align-items: center;
}

.action__click {
  background: linear-gradient(228.34deg, #FEF2EB 0%, #F1F1F1 46.31%, #E4E0EA 100%), #FFFFFF;
  color: var(--rts-button-1);
  transition: var(--transition);
  padding: 12px 18px;
  line-height: 1.2;
  border-radius: 6px;
}

.candidate__filter__area {
  display: flex;
  justify-content: space-between;
  background-color: var(--rts-white);
  align-items: center;
  padding: 30px;
  border-radius: 16px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .candidate__filter__area {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    justify-content: start;
  }
}

.candidate__filter {
  width: 40%;
}
@media screen and (max-width: 1200px) {
  .candidate__filter {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .candidate__filter {
    width: 100%;
  }
}

.candidate__filter__shorting {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.candidate__filter__shorting button {
  font-weight: 500;
}
.candidate__filter__shorting button.active {
  color: var(--rts-button-3);
  font-weight: 600;
}