.rts__appcenter{
    background-color: var(--rts-button-1);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    max-height: 430px;
    @include mq($lg){
        max-height: 100%;
    }
    @include mq($md){
        padding: 0;
    }
    @include mq($sm){
        border-radius: 0;
    }
    &>*{
        position: relative;
        z-index: 1;
    }
    &__shape{
        position: absolute;
        top: 0;
        left: 0;
    }
    .content__left{
        padding-left: 120px;
        padding-right: 80px;
        @include mq($xl){
            padding-left: 120px;
            margin-right: 50px;
        }
        @include mq($md){
            padding-left: 0;
            margin: 0 auto;
            padding-right: 0;
        }
    }
    .content__right{
        padding: 90px 80px 90px 0;
        @include mq($lg){
            padding: 90px 0;
            margin: 0 auto;
        }
        p{
            @include mq($md){
                padding: 0 15px;
            }
            
        }
    }
    .top-15px{
        top: 15px;
        @include mq($xl){
            top: 40px
        }
    }
}